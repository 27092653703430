import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { keyAddApi, keyListApi, keyDeleteApi } from "../../api/index";

import {
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Typography,
  theme,
  Card,
  Table,
  Space,
  Button,
  Popconfirm,
  Modal,
  Input,
} from "antd";

const { Title, Text } = Typography;

const Style = styled.div`
.container {
  width: 50%;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
  width: 100%;
  }
}
`;

export default function YourProfile({ notify, setLoading, logined }) {
  const [modelType, setModelType] = useState("add"); // add, edit
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [data, setData] = useState([]);

  const transData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        last_used: item.last_use_time || null,
        secret_key: item.key,
      };
    });
  };

  const getKeyList = () => {
    keyListApi().then((res) => {
      if (res.code === 0) {
        setData(transData(res.data.keys));
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    keyListApi().then((res) => {
      if (res.code === 0) {
        if(isMounted) {
          setData(transData(res.data.keys));
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

/*   const [value, setValue] = useState('checking value...');
useEffect(() => {
let isMounted = true;
fetchValue().then(() => {
      if(isMounted ){
      setValue("done!"); // no more error
      } 
    });
   return () => {
    isMounted = false;
  };
}, []); */


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const EllipsisMiddle: React.FC<{ suffixCount: number, children: string }> = ({
    suffixCount,
    children,
  }) => {
    const start = children.slice(0, children.length - suffixCount);
    const suffix = children.slice(-suffixCount).trim();
    return (
      <Text copyable={{text: start + suffix}} style={{ maxWidth: "100%" }} ellipsis={{ suffix }}>
        {start}
      </Text>
    );
  };

  const handleStatus = (record) => {
    console.log("click", record);
    // 更新状态，切换单元格的状态
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === record.key);
    newData[index].status = newData[index].status ? 0 : 1;
    setData(newData);
  };

  const handleEdit = (record) => {
    console.log("edit", record);
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === record.key);
    setModelType("edit");
    setIsModalOpen(true);
  };

  const handleDelete = useCallback((record) => {
    // 删除key
    keyDeleteApi({ key: record.secret_key }).then((res) => {
      if (res.code === 0) {
        notify({
          message: "Delete success",
          level: "success",
        });
        const newData = data.filter((item) => item.key !== record.key);
        setData(newData);
      }
    });
  }, [notify]);

  const handleAdd = () => {
    console.log("add");
    setModelType("add");
    setIsModalOpen(true);
  };

  // 表格
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Key",
      dataIndex: "secret_key",
      key: "secret_key",
      render: (text) => <EllipsisMiddle suffixCount={4}>{text}</EllipsisMiddle>,
    },
    /* {
      title: 'TRACKING',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <>
          {text === 1 ? (
            <Button type="primary" style={{background: '#4AA181'}} onClick={() => handleStatus(record)}>Enabled</Button>
          ) : (
            <Button type="primary" danger onClick={() => handleStatus(record)}>Disabled</Button>
          )}
        </>
      ),
      
    }, */
    {
      title: "Last used",
      key: "last_used",
      dataIndex: "last_used",
      render: (text) => {
        return (
          <>
            {text ? dayjs(text * 1000).format("YYYY-MM-DD HH:mm:ss") : "Never"}
          </>
        );
      },
      // render: (_, { last_used }) => (
      //   <>
      //     {last_used.map((tag) => {
      //       let color = tag.length > 5 ? 'geekblue' : 'green';
      //       if (tag === 'loser') {
      //         color = 'volcano';
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },

    {
      title: "Create time",
      key: "create_time",
      dataIndex: "create_time",
      render: (text) => {
        return (
          <>{text ? dayjs(text * 1000).format("YYYY-MM-DD HH:mm:ss") : "--"}</>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <FormOutlined className="icon-pointer" onClick={() => handleEdit(record)} /> */}
          <Popconfirm
            title="confirm delete?"
            okText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleDelete(record)}
          >
            <DeleteOutlined className="icon-pointer" style={{ color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleOk = useCallback(() => {
    if (!nameInput) {
      notify({
        message: "enter name",
        level: "error",
      });
      return;
    }
    /* {
    "code": 0,
    "data": {
        "key": "b7c73e47a4d944c78dc8f71b47ff7a6e"
    },
    "msg": "ok"
} */
    keyAddApi({ name: nameInput }).then((res) => {
      if (res.code === 0) {
        notify({
          message: "Add success",
          level: "success",
        });
        setIsModalOpen(false);
        getKeyList();
      }
    });
  }, [nameInput, notify]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Style>
      <div
        className="container"
        style={{
          padding: 20,
          height: "calc(100vh - 64px)",
        }}
      >
        <Title level={2} style={{ marginTop: 0 }}>
          Your profile
        </Title>
        <Card style={{ marginBottom: 15 }}>
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <InfoCircleOutlined
              style={{ marginRight: "1em", transform: "translateY(3px)" }}
            />
            <div>
              Do not share your APl key with others, or expose it in the browser
              or other client-side code. in order to protect the security of
              your accouOpenAl may also automatically disable any APl key that
              has leaked publicly.
            </div>
          </div>
        </Card>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="key"
          pagination={{ position: ["none"] }}
        />
        <Button
          type="primary"
          style={{ marginTop: 15 }}
          icon={<PlusOutlined />}
          size="large"
          onClick={() => handleAdd()}
        >
          Add
        </Button>
      </div>
      <Modal
        title={modelType === "edit" ? "Edit" : "Add"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter name"
          onChange={(e) => setNameInput(e.target.value)}
        />
      </Modal>
    </Style>
  );
}
