import styled from "styled-components";
import languages from "../libs/languages";
import { t, Translate } from "react-i18nify";
import React, { useState, useCallback, useEffect } from "react";
import { getExt, download } from "../utils";
import { file2sub, sub2vtt, sub2srt, sub2txt } from "../libs/readSub";
import sub2ass from "../libs/readSub/sub2ass";
import googleTranslate from "../libs/googleTranslate";
import SimpleFS from "@forlagshuset/simple-fs";
import {
  fileUploadApi,
  videoNewApi,
  transcribeApi,
  getTrApi,
  queryApi,
  editApi,
  errInfoApi,
  userInfoApi,
} from "../api/index";
import { Button, Flex, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  serveWhisiperApi,
  serveResplitApi,
  serveGetTrApi,
  servePollingApi,
  serveSubsApi,
} from "../api/serve";
import { useNavigate } from "react-router-dom";
// import FFmpeg from "@ffmpeg/ffmpeg";
// const { createFFmpeg, fetchFile } = FFmpeg;
const { fetchFile } = window.FFmpegUtil;
const { FFmpeg } = window.FFmpegWASM;
/* 
    <script src="/static/assets/ffmpeg/package/dist/umd/ffmpeg.js"></script>
    <script src="/static/assets/util/package/dist/umd/index.js"></script>
*/
// import FFmpeg from "../assets/ffmpeg/package/dist/umd/ffmpeg.js";
// import { fetchFile } from "../assets/util/package/dist/umd/index.js";

/* 
let obj = {
    "expires_time": 1719223078,
    "is_admin": false,
    "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxOTIxOTQ3OCwianRpIjoiZDhkY2Y4OWMtN2M2MS00ZDQ1LWJmODUtNTAyNTY4MTJiZDcxIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOnsiYWNjb3VudCI6IjgxNDY1OTA1NkBxcS5jb20iLCJpZCI6M30sIm5iZiI6MTcxOTIxOTQ3OCwiY3NyZiI6ImYwNzEwNmFmLTg0NGEtNDBkYy05YjY5LTUwMTg1MmQ2ZTU1ZiIsImV4cCI6MTcyMTgxMTQ3OH0.jNncNp2V7Ky2hv1NPTUsa7hMT5cBbUGfpNGELhrUIas",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxOTIxOTQ3OCwianRpIjoiYjgwMWEzMjItN2QxOS00NzEwLThhYWYtZTYxNTU0MmMwOTA1IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJpZCI6Mywibmlja25hbWUiOiI4MTQ2NTkwNTZAcXEuY29tIiwiYWNjb3VudCI6IjgxNDY1OTA1NkBxcS5jb20iLCJhdmF0YXIiOjEsImxhc3RfbG9naW5fdGltZSI6MTcxOTIxOTI1OCwibGFzdF9sb2dpbl9pcCI6IjEyNy4wLjAuMSJ9LCJuYmYiOjE3MTkyMTk0NzgsImNzcmYiOiI0ZDY0YTk1MS1iMzdjLTRhYjgtYmMzMy0xZmU0MTgzNjg3NDMiLCJleHAiOjE3MTkyMjMwNzh9.L1Uxm8JbP2kk98yq7lnVejA-vKnt90TUq-OATsZ_jEo"
}
*/

const Style = styled.div`
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // padding-bottom: 20px;
  // position: relative;
  // overflow: hidden;
  // background-color: rgb(0 0 0 / 100%);
  // border-left: 1px solid rgb(255 255 255 / 20%);
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  .ant-btn {
    margin-right: 10px;
  }
  // .import-btn {
  //   position: relative;

  // }
  .dropdown-menu {
    display: flex;
    flex-direction: column;
  }

  .import {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 48%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #3f51b5;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }

    .file {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .burn {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 100%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #673ab7;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .export {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 31%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #009688;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 48%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #009688;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .translate {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    select {
      width: 65%;
      outline: none;
      padding: 0 5px;
      border-radius: 3px;
    }

    .btn {
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 33%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #673ab7;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .hotkey {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    span {
      width: 49%;
      font-size: 13px;
      padding: 5px 0;
      border-radius: 3px;
      text-align: center;
      color: rgb(255 255 255 / 75%);
      background-color: rgb(255 255 255 / 20%);
    }
  }

  .bottom {
    padding: 10px;
    a {
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(255 255 255 / 30%);
      text-decoration: none;

      .title {
        color: #ffeb3b;
        padding: 5px 10px;
        animation: animation 3s infinite;
        border-bottom: 1px solid rgb(255 255 255 / 30%);
      }

      @keyframes animation {
        50% {
          color: #00bcd4;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .progress {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    height: 2px;
    background-color: rgb(0 0 0 / 50%);

    span {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      height: 100%;
      background-color: #ff9800;
      transition: all 0.2s ease 0s;
    }
  }
`;

// FFmpeg.createFFmpeg({ corePath: "/ffmpeg-core.js", log: true }).load();
let ffmpeg = null;
const fs = new SimpleFS.FileSystem();

export default function Header({
  player,
  waveform,
  newSub,
  undoSubs,
  clearSubs,
  language,
  subtitle,
  setLoading,
  formatSub,
  setSubtitle,
  setProcessing,
  notify,
  // videoId,
  // setVideoId,
  onOperation,
  videoFile,
  setVideoFile,
  info,
  updateInfo,
  setIsModalOpen,
  setModelContent,
  setPlayer,
}) {
  const navigate = useNavigate();
  const [translate, setTranslate] = useState("en");
  // const [videoFile, setVideoFile] = useState(null);
  const [fontFile, setFontFile] = useState(null);
  // 翻译时间
  const [pollingStart, setPollingStart] = useState(0);
  const [pollingEnd, setPollingEnd] = useState(0);
  const [timer, setTimer] = useState(null);
  const [pollingTime, setPollingTime] = useState(0);

  const fileInput = React.createRef();

  const errReport = async (api, query, res) => {
    await errInfoApi({
      api,
      query,
      res,
    });
  };

  useEffect(() => {
    async function mountedFunc() {
      if (videoFile) {
        console.log("视频上传了");
        let file = videoFile;
        const ext = getExt(file.name);
        const canPlayType = player.canPlayType(file.type);
        console.log(canPlayType);
        if (canPlayType === "maybe" || canPlayType === "probably") {
          await getFisrtFrame(file);
          const url = URL.createObjectURL(new Blob([file]));
          waveform.decoder.destroy();
          waveform.drawer.update();
          clearSubs();
          setSubtitle([
            newSub({
              start: "00:00:00.000",
              end: "00:00:01.000",
              text: t("SUB_TEXT"),
              text2: "",
            }),
          ]);
          player.src = url;
          await decodeAudioData(file);
          waveform.seek(0);
          player.currentTime = 0;
        } else {
          setLoading("");
          notify({
            message: `${t("VIDEO_EXT_ERR")}: ${file.type || ext}`,
            level: "error",
          });
        }
      }
    }
    mountedFunc();
  }, [videoFile, info]);

  const loadffmpeg = async () => {
    if (ffmpeg === null) {
      setLoading("加载依赖中");
      console.log("init ffmpeg");
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        console.log(message);
      });
      ffmpeg.on("progress", ({ progress, time }) => {
        setProcessing(progress * 100);
      });
      await ffmpeg.load({
        coreURL: "/assets/core/package/dist/umd/ffmpeg-core.js",
      });
      /* ffmpeg = createFFmpeg({
        corePath: "/ffmpeg-core.js",
        log: true,
      });
      await ffmpeg.load(); */
    } else if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }
  };

  const decodeAudioData = useCallback(
    async (file) => {
      try {
        console.log("decodeAudioData");
        await loadffmpeg();
        await ffmpeg.writeFile(file.name, await fetchFile(file));
        notify({
          message: t("DECODE_START"),
          level: "info",
        });
        const output = `${Date.now()}.mp3`;

        await ffmpeg.exec(["-i", file.name, "-ac", "1", "-ar", "8000", output]);
        const uint8 = await ffmpeg.readFile(output);
        // console.log('uint8,',uint8);
        waveform.drawer.update();

        setProcessing(0);
        notify({
          message: t("DECODE_SUCCESS"),
          level: "success",
        });
        const blob = new Blob([uint8], { type: "audio/mpeg" });
        const newFile = new File([blob], output, { type: "audio/mpeg" });
        getWhisiperFromAudio(newFile);
      } catch (error) {
        console.log("try decodeAudio err:");
        console.log(error);
        // setLoading("");
        setProcessing(0);
        notify({
          message: t("DECODE_ERROR"),
          level: "error",
        });
      }
    },
    [waveform, notify, setProcessing, setLoading]
  );

  const burnSubtitles = useCallback(async () => {
    try {
      // const { createFFmpeg, fetchFile } = FFmpeg;
      // const ffmpeg = createFFmpeg({ corePath: "/ffmpeg-core.js", log: true });
      loadffmpeg();
      setLoading(t("LOADING_FFMPEG"));
      await ffmpeg.load();
      setLoading(t("LOADING_FONT"));

      await fs.mkdir("/fonts");
      const fontExist = await fs.exists("/fonts/Microsoft-YaHei.ttf");
      if (fontExist) {
        const fontBlob = await fs.readFile("/fonts/Microsoft-YaHei.ttf");
        ffmpeg.FS(
          "writeFile",
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        );
      } else {
        const fontUrl = process.env.REACT_APP_HOST + "/Microsoft-YaHei.ttf";
        const fontBlob = await fetch(fontUrl).then((res) => res.blob());
        await fs.writeFile("/fonts/Microsoft-YaHei.ttf", fontBlob);
        ffmpeg.FS(
          "writeFile",
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        );
      }
      setLoading(t("LOADING_VIDEO"));
      ffmpeg.FS(
        "writeFile",
        videoFile ? videoFile.name : "sample.mp4",
        await fetchFile(videoFile || "sample.mp4")
      );
      setLoading(t("LOADING_SUB"));
      const subtitleFile = new File(
        [new Blob([sub2ass(subtitle)])],
        "subtitle.ass"
      );
      ffmpeg.FS("writeFile", subtitleFile.name, await fetchFile(subtitleFile));
      setLoading("");
      notify({
        message: t("BURN_START"),
        level: "info",
      });
      const output = `${Date.now()}.mp4`;
      await ffmpeg.run(
        "-i",
        videoFile ? videoFile.name : "sample.mp4",
        "-vf",
        `ass=${subtitleFile.name}:fontsdir=/tmp`,
        "-preset",
        videoFile ? "fast" : "ultrafast",
        output
      );
      const uint8 = ffmpeg.FS("readFile", output);
      download(URL.createObjectURL(new Blob([uint8])), `${output}`);
      setProcessing(0);
      notify({
        message: t("BURN_SUCCESS"),
        level: "success",
      });
    } catch (error) {
      setLoading("");
      setProcessing(0);
      notify({
        message: t("BURN_ERROR"),
        level: "error",
      });
    }
  }, [notify, setProcessing, setLoading, videoFile, subtitle]);

  const onSubtitleChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file) {
        const ext = getExt(file.name);
        if (["ass", "vtt", "srt", "json"].includes(ext)) {
          file2sub(file)
            .then((res) => {
              console.log(res);
              clearSubs();
              setSubtitle(res);
            })
            .catch((err) => {
              notify({
                message: err.message,
                level: "error",
              });
            });
        } else {
          notify({
            message: `${t("SUB_EXT_ERR")}: ${ext}`,
            level: "error",
          });
        }
      }
    },
    [notify, setSubtitle, clearSubs]
  );

  const onInputClick = useCallback((event) => {
    event.target.value = "";
  }, []);

  // subType: all:全部，original：仅原文，translation：仅译文
  const downloadSub = useCallback(
    (type, subType) => {
      let text = "";
      const name = `${Date.now()}.${type}`;
      switch (type) {
        case "vtt":
          text = sub2vtt(subtitle);
          break;
        case "srt":
          if (subType === "translation" && !subtitle[0].text2) {
            notify({
              message: "请先翻译",
              level: "error",
            });
            return;
          }
          text = sub2srt(subtitle, subType);
          break;
        case "ass":
          text = sub2ass(subtitle, subType);
          break;
        case "txt":
          text = sub2txt(subtitle);
          break;
        case "json":
          text = JSON.stringify(subtitle);
          break;
        default:
          break;
      }
      const url = URL.createObjectURL(new Blob([text]));
      download(url, name);
    },
    [subtitle]
  );
  const fontChange = useCallback(
    async (event) => {
      const file = event.target.files[0];
      setFontFile(file);
    },
    [setFontFile]
  );

  const downloadOnlyVideo = useCallback(async () => {
    download(URL.createObjectURL(videoFile), `${Date.now()}.mp4`);
  }, [videoFile]);

  const downloadVideo = useCallback(async () => {
    // let text = sub2srt(subtitle);
    // const srtFile = new File([new Blob([text])], 'subtitle.srt')

    // const { createFFmpeg, fetchFile } = FFmpeg;
    // const ffmpeg = createFFmpeg({ corePath: "/ffmpeg-core.js", log: true });
    const startTime = Date.now();
    setLoading(t("LOADING_FFMPEG"));
    await loadffmpeg();
    setLoading("合并字幕中");
    // await ffmpeg.load();

    try {
      await fs.mkdir("/fonts");
      const fontExist = await fs.exists(`/fonts/Microsoft-YaHei.ttf`);
      if (fontExist) {
        console.log("exist");
        console.log(fontExist);
        const fontBlob = await fs.readFile(`/fonts/Microsoft-YaHei.ttf`);
        /* ffmpeg.FS(
          "writeFile",
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        ); */
        await ffmpeg.writeFile(
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        );
      } else {
        console.log("empty");
        setLoading("加载字体中");
        const fontUrl = "/Microsoft-YaHei.ttf";
        const fontBlob = await fetch(fontUrl).then((res) => res.blob());
        setLoading("合并字幕中");
        console.log("fetch blob", fontBlob);
        // const fontBlob = new Blob([fontFile], {
        //   type: "application/octet-stream",
        // });
        await fs.writeFile(`/fonts/Microsoft-YaHei.ttf`, fontBlob);
        /* ffmpeg.FS(
          "writeFile",
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        ); */
        await ffmpeg.writeFile(
          `tmp/Microsoft-YaHei.ttf`,
          await fetchFile(fontBlob)
        );
      }

      // 视频文件
      /* ffmpeg.FS(
        "writeFile",
        videoFile ? videoFile.name : "sample.mp4",
        await fetchFile(videoFile || "sample.mp4")
      ); */
      await ffmpeg.writeFile(
        videoFile ? videoFile.name : "sample.mp4",
        await fetchFile(videoFile || "sample.mp4")
      );
      // 字幕文件
      const subtitleFile = new File(
        [new Blob([sub2ass(subtitle)])],
        "subtitle.ass"
      );

      // ffmpeg.FS("writeFile", subtitleFile.name, await fetchFile(subtitleFile));
      await ffmpeg.writeFile(subtitleFile.name, await fetchFile(subtitleFile));
      notify({
        message: t("BURN_START"),
        level: "info",
      });
      // 字体文件
      // if(!fontFile) {
      //     notify({
      //         message: `没选择字幕文件`,
      //         level: 'error',
      //     });
      //     return
      // }
      // ffmpeg.FS('writeFile', 'tmp/Microsoft-YaHei.ttf', await fetchFile(fontFile));

      const output = `${Date.now()}.mp4`;
      const burnCalls: string[] = [
        "-i",
        videoFile ? videoFile.name : "sample.mp4",
        "-vf",
        `ass=${subtitleFile.name}:fontsdir=/tmp`,
        output,
      ];

      await ffmpeg.exec(burnCalls);
      // const uint8 = ffmpeg.FS("readFile", output);
      const uint8 = await ffmpeg.readFile(output);
      setLoading("");
      download(URL.createObjectURL(new Blob([uint8])), `${output}`);

      setProcessing(0);
      notify({
        message: t("BURN_SUCCESS"),
        level: "success",
      });
      const endTime = Date.now();
      const duration = endTime - startTime;
      console.log(`burning took ${duration}ms`);
      // resplit_json + srt_json
      const resplitStr = localStorage.getItem("resplit_string");
      let logData = {
        whisperStr: "",
        resplitStr: resplitStr,
        subtitlesStr: localStorage.getItem("subtitle"),
      };
      const videoId = localStorage.getItem("video_id");
      console.log("video id get", videoId);

      onOperation({
        video_id: videoId,
        operation: 2, // 导出
        data: logData,
        time_spent: duration,
        status: 0, // 0：成功，1：失败
      });
    } catch (error) {
      setLoading("");
      setProcessing(0);
      notify({
        message: t("BURN_ERROR"),
        level: "error",
      });
      console.log("run error", error);
    }

    // console.log('video')
    // console.log(videoFile)
    // const url = URL.createObjectURL(videoFile);
    // download(url, `${Date.now()}.mp4`);
  }, [subtitle, videoFile, fontFile]);

  const onTranslate = useCallback(() => {
    setLoading(t("TRANSLATING"));
    googleTranslate(formatSub(subtitle), translate)
      .then((res) => {
        setLoading("");
        setSubtitle(formatSub(res));
        notify({
          message: t("TRANSLAT_SUCCESS"),
          level: "success",
        });
      })
      .catch((err) => {
        setLoading("");
        notify({
          message: err.message,
          level: "error",
        });
      });
  }, [subtitle, setLoading, formatSub, setSubtitle, translate, notify]);

  const curr_download = (file, name) => {
    const elink = document.createElement("a");
    elink.style.display = "none";
    elink.href = URL.createObjectURL(file);
    elink.download = name;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
  };
  /*
   *       const ffmpeg = new FFmpeg();
   * await ffmpeg.load();
   * await ffmpeg.writeFile("video.avi", ...);
   * await ffmpeg.writeFile("video.avi", await fetchFile("../video.avi"));
   * // ffmpeg -i video.avi video.mp4
   * await ffmpeg.exec(["-i", "video.avi", "video.mp4"]);
   * await ffmpeg.readFile("video.mp4");
   * const data = ffmpeg.readFile("video.mp4");
   */
  const getFisrtFrame = useCallback(
    async (file) => {
      console.log("getFisrtFrame");
      await loadffmpeg();
      try {
        // ffmpeg.FS("writeFile", file.name, await fetchFile(file));
        await ffmpeg.writeFile(file.name, await fetchFile(file));
        // ffmpeg -i input.mp4 -vf "fps=1" frame%04d.png
        const burnCalls: string[] = [
          "-i",
          file.name,
          "-vf",
          "fps=1",
          "frame%04d.png",
        ];
        await ffmpeg.exec(burnCalls);
        const uint8 = await ffmpeg.readFile("frame0001.png");
        // const uint8 = ffmpeg.FS("readFile", "frame0001.png");
        const uploadData = new FormData();
        const imgFile = new File([new Blob([uint8])], "frame0001.png");
        uploadData.append("file", imgFile);
        await fileUploadApi(uploadData).then(async (res) => {
          console.log("fileUploadApi res:");
          console.log(res);
          console.log(res.code === 0);
          if (res.code === 0) {
            let id = res.data[0].id;
            // await createNewVideo(id);
            await videoNewApi({
              frame: id,
              name: file.name,
              duration: player.duration,
              data: "{}",
            }).then((res) => {
              console.log("videoNewApi res:");
              console.log(res);
              if (res.code === 0) {
                localStorage.setItem("video_id", res.data.id);
                // setVideoId(res.data.id);
                // console.log('video id', videoId)
              } else {
                notify({
                  message: res.message,
                  type: "error",
                });
              }
            });
            // setVideoId(id);
          }
        });
        // download(URL.createObjectURL(new Blob([uint8])), `frame0001.png`);
      } catch (error) {}
    },
    [player]
  );

  const onVideoChange = useCallback(
    async (event) => {
      setLoading("加载依赖，视频解析中");
      const file = event.target.files[0];
      if (file) {
        const ext = getExt(file.name);
        const canPlayType = player.canPlayType(file.type);
        if (canPlayType === "maybe" || canPlayType === "probably") {
          setVideoFile(file);
          await getFisrtFrame(file);
          const url = URL.createObjectURL(new Blob([file]));
          waveform.decoder.destroy();
          waveform.drawer.update();
          await decodeAudioData(file);
          waveform.seek(0);
          player.currentTime = 0;
          clearSubs();
          setSubtitle([
            newSub({
              start: "00:00:00.000",
              end: "00:00:01.000",
              text: t("SUB_TEXT"),
              text2: "",
            }),
          ]);
          player.src = url;
          // 获取视频字幕和翻译
          // await getWhisiperJson(file);
          // 获取视频第一帧
        } else {
          setLoading("");
          notify({
            message: `${t("VIDEO_EXT_ERR")}: ${file.type || ext}`,
            level: "error",
          });
        }
      }
    },
    [
      newSub,
      notify,
      player,
      setSubtitle,
      waveform,
      clearSubs,
      decodeAudioData,
      setProcessing,
      setLoading,
    ]
  );

  // 音频听写
  // 传音频文件 file
  // 返回uid和srt
  const getWhisiperFromAudio = useCallback(
    async (file) => {
      // 判断余额是否足够
      let needBalance = (player.duration / 60) * 0.065;
      // console.log("needBalance", needBalance)
      // console.log('info ref', info)
      if (!info.current) {
        setLoading("");
        notify({
          message: `获取用户信息失败`,
          level: "error",
          duration: 5000,
        });
        return;
      }
      let { balance } = info.current;
      if (balance < needBalance) {
        setLoading("");
        notify({
          message: `余额不足`,
          level: "error",
        });
        setModelContent({
          title: "余额不足",
          content: `请充值后重试`,
          maskClosable: false,
          okText: "充值",
          cancelText: "取消",
          onOk: () => {
            setIsModalOpen(false);
            navigate("/user");
          },
          onCancel: () => {
            setIsModalOpen(false);
            window.location.reload();
          },
        });
        setIsModalOpen(true);
        return;
      }
      setLoading("视频字幕解析中");
      transcribeApi({ file: file })
        .then((res) => {
          console.log("getWhisiperFromAudio res");
          console.log(res);
          polling(res, 0);
        })
        .catch((error) => {
          setLoading("");
          notify({
            message: t("字幕解析失败"),
            level: "error",
          });
          console.error("Error:", error);
        });
    },
    [
      info,
      updateInfo,
      player,
      setIsModalOpen,
      setModelContent,
      setVideoFile,
      setPlayer,
    ]
  );

  // 获得用户的视频的转写结果
  const getWhisiperJson = useCallback(
    async (file) => {
      // 获取json
      setLoading("视频字幕解析中");
      const body = new FormData();
      body.append("file", file);
      body.append("language", "japanese");
      body.append("speaker_labels", "True");
      body.append(
        "prompt",
        "初めて、よろしくお願いします。どのような内容が必要でしょうか？例えば、自己紹介、旅行、趣味についてなど、教えてください。"
      );
      body.append("response_format", "verbose_json");
      const startTime = Date.now();
      await fetch("https://api.lemonfox.ai/v1/audio/transcriptions", {
        method: "POST",
        headers: {
          Authorization: "zAynpH02NsMdjl4oq8Hy8KXWpdBpJ2SG", //注意安全，严禁外传
        },
        body: body,
      })
        .then((response) => response.json())
        .then((res) => {
          const endTime = Date.now();
          const duration = endTime - startTime;
          console.log("getWhisiperJson Res");
          console.log(res);
          const stringifyRes = JSON.stringify(res);
          let logData = {
            whisperStr: stringifyRes,
            resplitStr: "",
            subtitlesStr: "",
          };
          const videoId = localStorage.getItem("video_id");
          onOperation({
            video_id: videoId,
            operation: 0, // 听写
            data: logData,
            time_spent: duration,
          });
          getResplitJson(stringifyRes);
        })
        .catch((error) => {
          setLoading("");
          notify({
            message: t("字幕解析失败"),
            level: "error",
          });
          console.error("Error:", error);
        });
    },
    [setLoading, notify]
  );
  // 将Whisper转写结果（whisper_api_json文件）变成可以生成重复请求字幕文件的格式（resplit_json文件）。只需要在第一次请求的时候使用（一个视频只需要请求一次）
  const getResplitJson = useCallback(
    (data) => {
      // setLoading("resplit_json文件生成中");
      let file = new File([new Blob([data])], "subtitle.json"); // download(file, "subtitle.json");
      // curr_download(file, "subtitle.json");
      // return resplit_json文件
      const body = new FormData();
      body.append("file", file);
      serveResplitApi(body)
        .then((res) => {
          // console.log(res);
          // getTranslateSrt(res);
          // josn是resplit_json文件，srt是字幕文件
          let { json, srt } = res;
          localStorage.setItem("resplit_string", JSON.stringify(json));
          // getSrt(json, false);
          let srtFile = new File([new Blob([srt])], "subtitle.srt");
          setLoading("字幕生成中");
          file2sub(srtFile)
            .then((res) => {
              console.log(res);
              clearSubs();
              setSubtitle(res);
              setLoading("");
              notify({
                message: t("字幕生成成功"),
                level: "success",
              });
            })
            .catch((err) => {
              notify({
                message: err.message,
                level: "error",
              });
            });
        })
        .catch((err) => {
          setLoading("");
          notify({
            message: "生成字幕失败",
            level: "error",
          });
        });
    },
    [setLoading, notify]
  );
  // 调取翻译。注意这个的环节是resplit之后。即用户已经看到并修改好了原字幕的视频.
  const getTranslateSrt = useCallback(
    (data) => {
      setPollingStart(Date.now());
      setLoading("字幕翻译中");
      // https://bideogo.azurewebsites.net/api/get_tr
      // Params:
      // tgt_lang: str, 翻译的目标语言。目前只能输入 zh-cn

      // Body:
      //     File: resplit_json文件
      // Return:
      // 这是一个非同步的函数。会返回一个getStatusUrl可以请求进度。结果显示在getStatusUrl里。
      let uid = localStorage.getItem("uid");
      let params = {
        uid,
        tgt_lang: "zh-cn",
        version: "0",
      };
      getTrApi(params)
        .then((res) => {
          console.log("serveGetTrApi res");
          console.log(res);
          polling(res, 1);
        })
        .catch((err) => {
          console.log("serveGetTrApi err");
          console.log(err);
          setLoading("");
          notify({
            message: t("翻译失败"),
            level: "error",
          });
        });
    },
    [setLoading, notify]
  );

  // 轮询(听写接口和翻译接口的返回地址)，queryData为{id,code}
  const polling = useCallback(
    (queryData, operateType) => {
      setPollingTime(pollingTime + 1);
      clearTimeout(timer);
      setTimer(null);
      setTimer(
        setTimeout(() => {
          queryApi(queryData)
            .then((res) => {
              console.log(res);
              let { runtimeStatus } = res;
              if (runtimeStatus === "Completed") {
                let { uid, srt } = res.output;
                // 记录操作
                setPollingEnd(Date.now());
                const duration = pollingEnd - pollingStart;
                console.log("Completed pollingTime:", pollingTime);
                console.log("subtitle", subtitle);
                // resplit_json + srt_json
                let logData = {
                  uid: uid,
                  subtitlesStr: localStorage.getItem("subtitle"),
                  srtStr: JSON.stringify(srt),
                };
                const videoId = localStorage.getItem("video_id");
                onOperation({
                  video_id: videoId,
                  operation: operateType, // 操作记录类型（0：听写，1：翻译）
                  data: logData,
                  time_spent: duration,
                  status: 0, // 0：成功，1：失败
                });
                // let file = new File([new Blob([JSON.stringify(output)])], "output.json");
                // curr_download(file, "output.json");
                // getSrt(json);
                localStorage.setItem("uid", uid);
                // getSrt(json, false);
                let srtFile = new File([new Blob([srt])], "subtitle.srt");
                setLoading("字幕生成中");
                file2sub(srtFile)
                  .then((res) => {
                    console.log(res);
                    clearSubs();
                    setSubtitle(res);
                    setLoading("");
                    notify({
                      message: t("字幕生成成功"),
                      level: "success",
                    });
                  })
                  .catch((err) => {
                    notify({
                      message: err.message,
                      level: "error",
                    });
                  });
              } else if (
                runtimeStatus === "Running" ||
                runtimeStatus === "Pending"
              ) {
                polling(queryData, operateType);
              } else if (runtimeStatus === "Failed") {
                errReport(
                  "/bideogo/runtime/webhooks/durabletask/instances/",
                  JSON.stringify(queryData),
                  JSON.stringify(res)
                );
                const videoId = localStorage.getItem("video_id");
                setPollingEnd(Date.now());
                const duration = pollingEnd - pollingStart;
                onOperation({
                  video_id: videoId,
                  operation: operateType, // 操作记录类型（0：听写，1：翻译）
                  data: {
                    uid: "",
                    subtitlesStr: "",
                    srtStr: "",
                  },
                  time_spent: duration,
                  status: 1, // 0：成功，1：失败
                });
                setLoading("");
                notify({
                  message: "字幕获取失败",
                  level: "error",
                });
              }
            })
            .catch((err) => {
              setLoading("");
              notify({
                message: "字幕获取失败",
                level: "error",
              });
            });
        }, 6000)
      );
    },
    [timer, pollingTime, setPollingTime, pollingStart, setPollingEnd, subtitle]
  );
  // 将 resplit_json文件 变成字幕
  const getSrt = useCallback(
    (data, isTranslate = true) => {
      setLoading("字幕生成中");
      // https://bideogo.azurewebsites.net/api/subs?
      // original: Boolean 是否保留原始字幕
      // translations: 英文逗号分隔的语言代码。目前请输入 zh-cn
      let body = new FormData();
      let file = new File([new Blob([JSON.stringify(data)])], "output.json");

      body.append("file", file);
      let url = `https://bideogo.azurewebsites.net/api/subs?original=${true}`;
      if (isTranslate) url += `&translations=zh-cn`;
      serveSubsApi(url, body)
        .then((res) => {
          console.log(res);
          let { ass, srt } = res;
          // let assFile = new File([new Blob([ass])], "subtitle.ass");
          // window.loadASS(assFile);
          let srtFile = new File([new Blob([srt])], "subtitle.srt");
          file2sub(srtFile)
            .then((res) => {
              console.log(res);
              clearSubs();
              setSubtitle(res);
              setLoading("");
              notify({
                message: t("字幕生成成功"),
                level: "success",
              });
            })
            .catch((err) => {
              notify({
                message: err.message,
                level: "error",
              });
            });
          // loadSrt(srtFile);
          // curr_download(assFile, "subtitle.ass");
        })
        .catch((error) => {
          setLoading("");
          notify({
            message: t("字幕文件获取失败"),
            level: "error",
          });
        });
    },
    [setLoading, notify]
  );

  const menuItmes = [
    {
      key: "1",
      label: (
        <Button type="primary" onClick={downloadVideo}>
          导出视频
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button type="primary" onClick={() => downloadSub("srt", "original")}>
          原文srt
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="primary"
          onClick={() => downloadSub("srt", "translation")}
        >
          译文srt
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          type="primary"
          onClick={() => curr_download(videoFile, `${Date.now()}.mp4`)}
        >
          导出原视频
        </Button>
      ),
    },
  ];
  return (
    <Style className="tool">
      <Button type="primary" onClick={getTranslateSrt}>
        翻译
      </Button>
      {/* <Button type="primary" onClick={downloadVideo}>导出视频</Button>
        <Button type="primary" onClick={() => downloadSub("srt")}>导出srt</Button> */}
      <Dropdown menu={{ items: menuItmes }}>
        <Button type="primary">
          导出
          <DownOutlined />
        </Button>
      </Dropdown>

      <Button type="primary" onClick={() => fileInput.current.click()}>
        打开视频
      </Button>
      <input
        className="file"
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={onVideoChange}
        onClick={onInputClick}
      />
      {/* <div className="btn" onClick={getTranslateSrt}>
            翻译
          </div> */}
      {/* <div className="top">
        <div className="import">
          <div className="btn">
            <Translate value="OPEN_VIDEO" />
            <input
              className="file"
              type="file"
              onChange={onVideoChange}
              onClick={onInputClick}
            />
          </div>
          <div className="btn" onClick={getTranslateSrt}>
            翻译
          </div>
        </div>
        <div className="burn" onClick={downloadVideo}>
          <div className="btn">
            <Translate value="EXPORT_VIDEO" />
          </div>
        </div>

        <div className="export">
          <div className="btn" onClick={() => downloadSub("srt")}>
            <Translate value="EXPORT_SRT" />
          </div>
        </div>
      </div> */}
    </Style>
  );
}
