import cookie from "react-cookies";
import { refreshTokenApi } from "../api";
import { notification } from "antd";

export const userAgent = window.navigator.userAgent;
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

export function getExt(url) {
    return url.trim().toLowerCase().split('.').pop();
}

export function sleep(ms = 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function download(url, name) {
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.href = url;
    elink.download = name;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

export function getKeyCode(event) {
    const tag = document.activeElement.tagName.toUpperCase();
    const editable = document.activeElement.getAttribute('contenteditable');
    if (tag !== 'INPUT' && tag !== 'TEXTAREA' && editable !== '' && editable !== 'true') {
        return Number(event.keyCode);
    }
}

export function isPlaying($video) {
    return !!($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > 2);
}

export const getToken = () => {
    const token = cookie.load("token");
    const expires_time = cookie.load("expires_time");
    let expired = expires_time * 1000 <= Date.now();
    if (expired || !token || token === "undefined") {
      return "";
    } else {
      return token;
    }
  };

  export const getRefreshToken = () => {
    let refresh_token = cookie.load("refresh_token");
    if(!refresh_token) return ''
    else return refresh_token
  }

  const setToken = (res) => {
    let { token, refresh_token, expires_time } = res.data;
    cookie.save("token", token);
    cookie.save("expires_time", expires_time);
    if (refresh_token) cookie.save("refresh_token", refresh_token);
  };

  export const isLogined = async () => {
    let token = await getToken()
    if(!token) {
        // notify
        let refresh_token = await getRefreshToken()
        if(!refresh_token) {
            notification.error({ message: "请先登录" });
            return false
        } else {
            return refreshTokenApi(refresh_token).then((res) => {
                if (res.code === 0) {
                  setToken(res);
                  return true
                } else {
                    notification.error({ message: '登陆已失效，请重新登录' });
                    return false
                }
            }).catch((err) => {
                notification.error({ message: '请先登录' });
                return false
            });
        }
        
      } else {
        return true
      }
  }