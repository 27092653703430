import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Typography,
  message,
  Upload,
  Input,
  Dropdown,
  Space,
} from "antd";
// import { InboxOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { download, isLogined } from "../utils";
import { errInfoApi, ytdlApi, ytdlProgressApi } from "../api/index";
import cookie from "react-cookies";
// import SimpleFS from "@forlagshuset/simple-fs";
// const fs = new SimpleFS.FileSystem();

const { Title } = Typography;
const { Dragger } = Upload;

const Style = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: #fff;
  color: #fff;
  padding: 30px 50px;
  
  .header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    
    .head-left {
      // border: 1px solid #333;
      width: 60px;
      height: 60px; 
      border-radius: 50%;
8001      overflow: hidden;
      // border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      .head-logo {
        width: 60%;
        height: 60%;
      }
    }
    .head-right {
      border: 1px solid #333;
    }
  }
  .title {
    text-align: center;
    h1 {
      color: #fff !important
    };
    h2 {
      color: #fff !important
    };
  }
  .upload-main {
    margin: 100px auto;
    width: 600px;
    height: 400px;
    box-sizing: border-box;
    .ant-upload-drag-container {
      p {
        color: #fff;
      }
    }
  }
  .upload-main-online {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .url-input {
      flex: 1;
    }
    .url-btn {
      margin-left: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .foot-sample {
      width: 20%;
      height: 300px;
      border: 1px solid #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .sample-title {
      }
    }
  }
`;

export default function UploadPage({
  videoFile,
  setVideoFile,
  logined,
  setLogined,
  setLoading,
  notify
}) {
  const navigate = useNavigate();
  const [onlineUrl, setOnlineUrl] = useState();
  const [timer, setTimer] = useState(null);
  const [pollingTime, setPollingTime] = useState(0);

  useEffect(() => {
    console.log("login info");
    console.log(logined);
  }, [logined]);

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      console.log(file);
      const logined = await isLogined();
      if (!logined) {
        console.log("not logined");

        return false;
      } else {
        console.log("logined");
        setVideoFile(file);
        return false;
      }
      /* let token = await getToken()
      if(!token) {
        // notify
        let refresh_token = await getRefreshToken()
        if(!refresh_token) {
          console.log('请先登录')
          notify({
            message: `请先登录`,
            level: "error",
          });
        }
        return false;
      } */
    },
  };

  const toLogin = () => {
    navigate("/login");
  };
  const toSetting = (id) => {
    localStorage.setItem("currMenuKey", id)
    console.log("toSetting", id);
    navigate("/setting");
  };

  const samples = [
    { title: "标题文字", url: "", desc: "描述文字" },
    { title: "标题文字", url: "", desc: "描述文字" },
    { title: "标题文字", url: "", desc: "描述文字" },
    { title: "标题文字", url: "", desc: "描述文字" },
  ];

  const errReport = (api, query, res) => {
    errInfoApi({api, query, res});
  }

  const getOnlineVideo = useCallback(async () => {
    console.log("online url:");
    console.log(onlineUrl);
    if (!logined) {
      message.error("请先登录");
      return;
    }
    if (!onlineUrl) {
      message.error("请输入视频链接");
      return;
    }
    setLoading("视频解析中");
    ytdlApi(onlineUrl).then((res) => {
      console.log("ytdlRes:", res);
      let { id } = res;
      polling(id);
    }).catch(err => {
      setLoading("");
      notify({
        message: "视频解析失败",
        level: "error",
      });
    });
  }, [onlineUrl, setLoading, notify]);

  const polling = useCallback(
    (id) => {
      setPollingTime(pollingTime + 1);
      clearTimeout(timer);
      setTimer(null);
      setTimer(
        setTimeout(() => {
          ytdlProgressApi(id).then((res) => {
            console.log(res);
            let { runtimeStatus } = res;
            if (runtimeStatus === "Completed") {
              let { output } = res;
              downloadVideo(output);
            } else if(runtimeStatus === "Failed") {
              errReport('/ytdlp-voilatech2/runtime/webhooks/durabletask/instances/',id,JSON.stringify(res))
              setLoading("");
              notify({
                message: "视频解析失败",
                level: "error",
              });
            } else {
              polling(id);
            }
          }).catch(err => {
            setLoading('')
            notify({
              message: '视频解析失败',
              level: "error",
            });
          });
        }, 5000)
      );
    },
    [setLoading, notify]
  );

  const downloadVideo = useCallback(
    async (url) => {
      // const fontUrl = "https://work.chenkf.cn/Microsoft-YaHei.ttf";
      // const videoBlob = await fetch(url).then((res) => res.blob());
      await fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((res) => {
          setLoading("");
          console.log(res);
          let file = new File([res], "input.mp4", { type: "video/mp4" });
          console.log(file);
          setVideoFile(file);
        });
    },
    [setVideoFile]
  );

  const logout = useCallback(() => {
    cookie.remove("token")
    cookie.remove("refresh_token")
    cookie.remove("expires_time")
    setLogined(false)
  }, [setLogined])

  const dropItems = [
    {
      key: "1",
      label: (
        // <a target="_blank" rel="noopener noreferrer" href="/user">
        //   用户中心
        // </a>
        <span onClick={() =>toSetting('2')}>用户中心</span>
      ),
    },
    {
      key: "2",
      label: (
        <span onClick={() =>toSetting('1')}>api key管理</span>
      ),
    },
    {
      key: "3",
      label: (
        <span onClick={() => {window.location.href="https://ai.voilatech.co.jp/docs/"}}>api 文档</span>
      ),
    },
    {
      key: "4",
      label: (
        <span onClick={() => logout()}>登出</span>
      ),
    },
  ];

  return (
    <Style className="upload-page">
      <div className="header">
        <div className="head-left">
          <img className="head-logo" src="/images/logo.png" alt="log加载失败" />
        </div>
        {logined ? (
          <div>
            <Dropdown overlayClassName="dropdown-upload-header" menu={{ items: dropItems }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space style={{cursor:'pointer'}} >
                  <UserOutlined />
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        ) : (
          <Button type="primary" onClick={toLogin}>
            登录
          </Button>
        )}
      </div>
      <div className="title">
        <Title>主标题，这是一段文字</Title>
        <Title level={2}>副标题，这是另外一段文字</Title>
      </div>
      <div className="upload-main">
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">上传图示</p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
        <div className="upload-main-online">
          {/* <span>YouTube地址：</span> */}
          <Input
            className="url-input"
            placeholder="支持从Youtube导入，在此粘贴YouTube视频链接"
            value={onlineUrl}
            onChange={(e) => {
              setOnlineUrl(e.target.value);
            }}
          />
          <Button type="primary" className="url-btn" onClick={getOnlineVideo}>
            导入
          </Button>
        </div>
      </div>
      <div className="footer">
        {samples.map((item, index) => {
          return (
            <div className="foot-sample" key={index}>
              <div className="sample-title">{item.title}</div>
              <img src={item.url} alt="示例图片" />
              <div className="sample-title">{item.desc}</div>
            </div>
          );
        })}
      </div>
    </Style>
  );
}
