import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import styled from "styled-components";
import {
  PlayCircleFilled,
  ExpandOutlined,
  ArrowRightOutlined,
  PauseCircleFilled,
} from "@ant-design/icons";
import { Slider, Input, Button } from "antd";
import DT from "duration-time-conversion";
const Style = styled.div`
  height: 300px;
  .video-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;
    .icon-play {
      cursor: pointer;
    }
    .icon-full {
      cursor: pointer;
    }
  }
  .nowarp {
    white-space: nowrap;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .replace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .ant-input-group-addon {
      background: #eee;
    }
  }
  .replace-tip {
    margin-top: 10px;
    padding-left: 150px;
  }
`;

const Duration = (props) => {
  const getDuration = useCallback((time) => {
    time = time === Infinity ? 0 : time;
    return DT.d2t(time).split(".")[0];
  }, []);

  return (
    <div className="duration">
      <span>
        {getDuration(props.currentTime)} /{" "}
        {getDuration(props.player.duration || 0)}
      </span>
    </div>
  );
};
export default function Controler(props) {
  const onChange = (value) => {
    console.log("onChange: ", value);
    const currentTime = (value / 100) * props.player.duration;
    props.player.currentTime = currentTime;
  };

  const clickPlayIcon = useCallback(() => {
    if (props.player.paused) {
      props.player.play();
    } else {
      props.player.pause();
    }
  }, [props]);

  return (
    <Style>
      {props.player ? (
        <React.Fragment>
          <div className="video-controls">
            {props.player.paused ? (
              <PlayCircleFilled
                className="icon-play"
                style={{ fontSize: "30px" }}
                onClick={clickPlayIcon}
              />
            ) : (
              <PauseCircleFilled
                className="icon-play"
                style={{ fontSize: "30px" }}
                onClick={clickPlayIcon}
              />
            )}
            <Duration {...props} />
            <ExpandOutlined
              className="icon-full"
              style={{ fontSize: "30px" }}
              onClick={() => props.setFullScreen(!props.fullScreen)}
            />
          </div>
          <Slider
            step={0.1}
            value={(props.currentTime / props.player.duration) * 100}
            tooltip={{ open: false }}
            onChange={onChange}
          />
          <div className="replace">
            <div className="nowarp mr-10">文字替换</div>
            <Input placeholder="搜索内容" className="mr-10" />
            <ArrowRightOutlined className="mr-10" />
            <Input placeholder="替换内容" className="mr-10" />
            <Button type="primary">全部替换</Button>
          </div>
          <div className="replace">
            <div className="nowarp mr-10">字幕时间</div>
            <Input
              addonBefore="人物讲话后"
              addonAfter="秒"
              defaultValue={-0.3}
              className="mr-10"
            />
            <Button type="primary">全部调整</Button>
          </div>
          <div className="replace-tip">
            输入负数即为在人物讲话前出现（默认-0.3秒）
          </div>
        </React.Fragment>
      ) : null}
    </Style>
  );
}
