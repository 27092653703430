import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { usageApi, balanceListApi } from "../../api/index";

import {
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Typography,
  theme,
  Card,
  Table,
  Space,
  Button,
  Popconfirm,
  Modal,
  Input,
  Pagination,
} from "antd";

const { Title, Text } = Typography;

const Style = styled.div`
  .container {
    width: 50%;
    margin: 0 auto;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
  }
`;
export default function Usage({ notify, setLoading, logined }) {
  const [tableLoading, setTableLoding] = useState(false);
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const getUsageList = useCallback((page, pageSize) => {
    setTableLoding(true)
    let query = {
      page: page,
      pagesize: pageSize,
    };
    balanceListApi(query).then((res) => {
      if (res.code === 0) {
        let { list, total, page, pagesize } = res.data;
        console.log("balanceListApi", list);
        setData(list);
        setTotal(total);
      }
    }).finally(() => {
      setTableLoding(false)
})
  }, [])


  useEffect(() => {
    getUsageList(currPage, currPageSize);
  }, [currPage, currPageSize]);

  // 表格
  const columns = [
    {
      title: "Time",
      dataIndex: "create_time",
      key: "create_time",
      render: (text) => {
        return (
          <>
            {text ? dayjs(text * 1000).format("YYYY-MM-DD HH:mm:ss") : "Never"}
          </>
        );
      },
    },
    {
      title: "API",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  /* {
      "amount": "-1.300000",
      "change_type": 1,
      "create_time": 1722586756,
      "id": 51,
      "name": "whisper",
      "taget": "operation#100"
  } */



  return (
    <Style>
      <div
        className="container"
        style={{
          padding: 20,
          height: "calc(100vh - 64px)",
        }}
      >
        <Title level={2} style={{ marginTop: 0 }}>
          API Usage
        </Title>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          loading={tableLoading}
          pagination={{
            total: total,
            onChange: (page, pageSize) => {
              getUsageList(page, pageSize)
            },
            showSizeChanger: true,
          }}
        />

        {/* <Pagination
          style={{ marginTop: 20 }}
          total={total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={currPageSize}
          defaultCurrent={currPage}
          onChange={(page, pageSize) => {
            getUsageList({ page, pagesize:pageSize })
          }}
        /> */}
      </div>
    </Style>
  );
}
