import styled from "styled-components";
import {
  GiftOutlined,
  ArrowRightOutlined,
  EllipsisOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  color: #000;
  font-weight: 400;
  .items-center {
    display: flex;
    align-items: center;
  }
  font-size: 14px;
  @media only screen and (max-width: 599px) {
    font-size: 16px;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    width: 100%;
    background-color: #fff5e8;
  }
  .header-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 960px;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
    padding: 1em;
    color: #000;
    .logo {
      width: 2em;
      height: 2em;
      margin-right: 0.5em;
    }
  }
  .main-1 {
    padding: 8em 1.2em 0;
    width: 960px;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-center {
      color: #868e96;
      border: 1px solid #868e96;
      border-radius: 5em;
      padding: 0 0.5em;
      font-weight: 700;
      font-size: 0.78rem;
      line-height: 1;
    }
    .h1 {
      font-size: 4.57em;
      font-weight: 700;
      line-height: 1.3;
      &.h1-FD7E14 {
        color: #fd7e14;
      }
    }
    .start-btn {
      border-color: #000;
      width: 10.6rem;
      height: 3.2rem;
      font-size: 1.3rem;
      &:hover {
        border-color: #000;
        background-color: #d9d9d9;
        color: #000;
      }
    }
    .tags {
      margin-top: 9.15rem; // 128
      margin-bottom: 1.43rem; // 20
      .ant-tag {
        padding: 0.5rem 1rem;
      }
      .tag-text {
        font-weight: 600;
        font-size: 1rem;
      }
      .icon-svg {
        margin-right: 0.5rem;
      }
    }
    position: relative;

    z-index: 1;
    .bg-box {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      max-width: 100vw;
      overflow: hidden;
      .bg-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 859px;
        height: 100%;
      }
    }
  }
  .main-2 {
    border-top: 1px solid #e9e9e9;
    padding-top: 6.86rem;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 720px) {
      width: 100%;
    }
    .title {
      font-size: 2.285rem;
      font-weight: 600;
      margin-left: 0.5em;
    }
    .subtitle {
      margin-top: 0.71rem;
      font-size: 1.14rem;
      font-weight: 400;
      color: #868e96;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.143rem;
        text-align: center;
        width: 360px;
        box-sizing: border-box;
        @media only screen and (max-width: 720px) {
          width: 100%;
        }
        .svg-box {
          border-radius: 50%;
          background: #e7f6f8;
          width: 3.57rem;
          height: 3.57rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #15aabf;
        }
        .card-title {
          font-size: 1.714rem;
          font-weight: 600;
          margin: 0.71rem 0;
        }
        .card-content {
          font-size: 1.143rem;
          color: #868e96;
        }
      }
    }
  }
  .main-3 {
    width: 960px;
    margin-top: 9.15rem;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
    .main3-cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .main3-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding: 1.43rem;
        width: 480px;
        padding: 0.71rem;
        box-sizing: border-box;
        @media only screen and (max-width: 960px) {
          width: 100%;
        }
        .title-box {
          display: flex;
          align-items: center;
          .svg-box {
            margin-right: 0.5em;
          }
          .title {
            font-size: 2rem;
            font-weight: 600;
          }
        }
        .content {
          color: #868e96;
          font-size: 1.14rem;
          margin: 0.71rem 0 1.43rem;
        }
        .info-list {
          .info-item {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            .icon-box {
              color: #40c057;
              background: #ecf9ee;
              margin-right: 0.86rem;
              border-radius: 50%;
              width: 1.3rem;
              height: 1.3rem;
              display: flex;
              justify-content: center;
              align-items: center;
              &.yellow {
                color: #fab005;
                background: #fef7e6;
              }
            }
            .info-text {
              flex: 1;
            }
          }
        }
      }
    }
  }
  .main4-title {
    font-size: 2.285rem;
    font-weight: 600;
    text-align: center;
    margin-top: 9.14rem;
  }
  .main4-subtitle {
    font-size: 1.14rem;
    color: #868e96;
    text-align: center;
    margin-top: 0.71rem;
    margnin-bottom: 1.43rem;
  }
  .main4 {
    // min-width: 480px;
    width: 688px;
    @media only screen and (max-width: 688px) {
      width: 100%;
    }
    margin-top: 1.43rem;
    padding: 2.28rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border: 1px solid #e9e9e9;
    border-radius: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    alalign-items: center;
    .main4-card {
      display: flex;
      flex: 1;
      @media only screen and (max-width: 480px) {
        flex: unset;
        width: 100%;
      }
      flex-direction: column;
      align-items: flex-start;
      .main4-card-title {
        font-size: 1.72rem;
        fweight: 600;
        .large {
          font-size: 2rem;
        }
        .small {
          font-size: 1.14rem;
        }
      }
      .main4-card-content {
        font-size: 1.14rem;
        color: #868e96;
        margin: 0.3rem 0;
      }
      .main4-card-list {
        .main4-card-item {
          font-size: 1.14rem;
          color: #868e96;
          margin-bottom: 0.3rem;
          display: flex;
          align-items: center;
          .dot {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            background: #868e96;
          }
          .list-content {
            margin-left: 0.5rem;
          }
        }
      }
    }
    .plus-icon {
      margin: 0 2rem 0 1rem;
      @media only screen and (max-width: 480px) {
        margin: 0;
        width: 1rem;
        display: none;
      }
    }
  }
  .start-btn2 {
    border-color: #000;
    width: 17.3rem;
    height: 3.2rem;
    font-size: 1.3rem;
    &:hover {
      border-color: #000 !important;
      background-color: #d9d9d9 !important;
      color: #000 !important;
    }
  }
  .btn-foot {
    margin-top: 7.86rem;
    margin-bottom: 7rem;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
    border: 1px solid #e9e9e9;
    font-weight: 600;
    padding: 0.5rem 1rem;
    .link {
      color: #228be6;
      margin-left: 0.5rem;
    }
  }
`;
export default function staticHome({}) {
  return (
    <Style>
      <div className="header">
        <span style={{ color: "#000", fontWeight: 500 }}>
          <GiftOutlined style={{ marginRight: "0.5em" }} />
          First month for free!
        </span>
        <span style={{ color: "#fd7e14" }}>
          <span style={{ margin: "0 0.2em 0 0.5em" }}>Get started</span>
          <ArrowRightOutlined />
        </span>
      </div>
      <div className="header-2">
        <div className="items-center">
          <img className="logo" src="/images/logo.png" alt="log加载失败" />
          <span style={{ fontWeight: 600, fontSize: "1.3rem" }}>
            Lemonfox.ai
          </span>
        </div>
        <div className="items-center" style={{ fontWeight: 600 }}>
          <span style={{ marginRight: "0.5em" }}>APIs</span>
          <EllipsisOutlined />
        </div>
      </div>
      <div className="main-1">
        <div className="btn-center">LOW COST AI</div>
        <div className="h1">Easy-to-Use</div>
        <div className="h1 h1-FD7E14">Transcription & AI APIs</div>
        <div
          style={{ marginTop: "0.86em", fontSize: "1.14em", fontWeight: 400 }}
        >
          Use our APIs to create audio transcripts, text and images.
        </div>
        <Button
          className="start-btn"
          size="large"
          style={{ marginTop: "1.43em", fontWeight: 600 }}
        >
          Get Started >
        </Button>
        <div className="tags">
          <Tag bordered={false} color="#E7F6F8" style={{ color: "#15aabf" }}>
            <span className="icon-svg svg-rotate">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="rotate(-45)translate(0,3)"
              >
                <path d="M4 8l4 -4"></path>
                <path d="M14 4l-10 10"></path>
                <path d="M4 20l16 -16"></path>
                <path d="M20 10l-10 10"></path>
                <path d="M20 16l-4 4"></path>
              </svg>
            </span>
            <span className="tag-text">Speech-to-Text API</span>
          </Tag>
          <Tag bordered={false} color="#ECF9EE" style={{ color: "#40c057" }}>
            <span className="icon-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(0,3)"
              >
                <path d="M8 9h8"></path>
                <path d="M8 13h6"></path>
                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
              </svg>
            </span>
            <span className="tag-text">LLM Chat API</span>
          </Tag>
          <Tag bordered={false} color="#FEF7E6" style={{ color: "#fab005" }}>
            <span className="icon-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(0,3)"
              >
                <path d="M15 8h.01"></path>
                <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z"></path>
                <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"></path>
                <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"></path>
              </svg>
            </span>
            <span className="tag-text">SDXL Image API</span>
          </Tag>
        </div>
        {/* <img className="bg-img" src="/images/bg-home.png" alt="" /> */}
        <span className="bg-box">
          <span
            className="bg-img"
            style={{ backgroundImage: "url(/images/bg-home.png)" }}
          ></span>
        </span>
      </div>
      <div className="line"></div>
      <div className="main-2">
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="rotate(-45)translate(0,3)"
            >
              <path d="M4 8l4 -4"></path>
              <path d="M14 4l-10 10"></path>
              <path d="M4 20l16 -16"></path>
              <path d="M20 10l-10 10"></path>
              <path d="M20 16l-4 4"></path>
            </svg>
          </span>
          <span className="title">Speech-to-Text API</span>
        </div>
        <div className="subtitle">
          Transcribe audio files within seconds for less than $0.17 per hour of
          speech.
        </div>
        <div className="cards">
          <div className="card">
            <div className="svg-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 5h7"></path>
                <path d="M9 3v2c0 4.418 -2.239 8 -5 8"></path>
                <path d="M5 9c0 2.144 2.952 3.908 6.7 4"></path>
                <path d="M12 20l4 -9l4 9"></path>
                <path d="M19.1 18h-6.2"></path>
              </svg>
            </div>
            <div className="card-title">100+ Languages</div>
            <div className="card-content">
              Supporting many languages with the option to translate in our API.
            </div>
          </div>

          <div className="card">
            <div className="svg-box">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
              </svg>
            </div>
            <div className="card-title">Speaker Recognition</div>
            <div className="card-content">
              Identify different speakers in the audio with our diarization
              option.
            </div>
          </div>
          <div className="card">
            <div className="svg-box">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"></path>
                <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                <path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
              </svg>
              
            </div>
            <div className="card-title">Performance</div>
            <div className="card-content">
              Get accurate transcriptions with minimal latency and the most
              competitive pricing available
            </div>
          </div>
          <div className="card">
            <div className="svg-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"></path>
              </svg>
            </div>
            <div className="card-title">High Accuracy</div>
            <div className="card-content">
              Whisper large-v3 is the latest and most precise speech recognition
              AI model.
            </div>
          </div>
        </div>
      </div>
      <div className="main-3">
        <div className="main3-cards">
          <div className="main3-card">
            <div className="title-box">
              <span className="svg-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M8 9h8"></path>
                  <path d="M8 13h6"></path>
                  <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                </svg>
              </span>
              <span className="title">LLM Chat API</span>
            </div>
            <div className="content">
              Generate text and chat with our AI model that delivers ChatGPT
              level performance at a fraction of the cost.
            </div>
            <div className="info-list">
              <div className="info-item">
                <span className="icon-box">
                  <CheckOutlined />
                </span>
                <span className="info-text">
                  Different AI models (Mixtral, Llama 3.1 8B and 70B)
                </span>
              </div>
              <div className="info-item">
                <span className="icon-box">
                  <CheckOutlined />
                </span>
                <span className="info-text">Streaming requests supported</span>
              </div>
              <div className="info-item">
                <span className="icon-box">
                  <CheckOutlined />
                </span>
                <span className="info-text">
                  OpenAI-compatible API, making it easy to switch
                </span>
              </div>
            </div>
          </div>
          <div className="main3-card">
            <div className="title-box">
              <span className="svg-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 8h.01"></path>
                  <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z"></path>
                  <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"></path>
                  <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"></path>
                </svg>
              </span>
              <span className="title">SDXL Image API</span>
            </div>
            <div className="content">
              Generate high-quality images from text prompts in just a few
              seconds.
            </div>
            <div className="info-list">
              <div className="info-item">
                <span className="icon-box yellow">
                  <CheckOutlined />
                </span>
                <span className="info-text">
                  High-quality images with Stable Diffusion XL
                </span>
              </div>
              <div className="info-item">
                <span className="icon-box yellow">
                  <CheckOutlined />
                </span>
                <span className="info-text">
                  Create unique illustrations, photos and more
                </span>
              </div>
              <div className="info-item">
                <span className="icon-box yellow">
                  <CheckOutlined />
                </span>
                <span className="info-text">
                  Just $0.50 per 100 generated images
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main4-title">Simple Affordable Pricing</div>
      <div className="main4-subtitle">Try our APIs for 1 month for free.</div>
      <div className="main4">
        <div className="main4-card">
          <div className="main4-card-title">
            <span className="large">$5</span> / month
            <InfoCircleOutlined style={{ marginLeft: "0.8rem" }} />
          </div>
          <div className="main4-card-content">Includes 10 million credits:</div>
          <div className="main4-card-list">
            <div className="main4-card-item">
              <CheckOutlined />
              <div className="list-content">30 hours speech-to-text</div>
            </div>
            <div className="main4-card-item">
              <CheckOutlined />
              <div className="list-content">
                or 10 million Llama-8B chat tokens
              </div>
            </div>
            <div className="main4-card-item">
              <CheckOutlined />
              <div className="list-content">
                or 6 million Mixtral tokens
                <InfoCircleOutlined />
              </div>
            </div>
            <div className="main4-card-item">
              <CheckOutlined />
              <div className="list-content">or 1,000 images</div>
            </div>
          </div>
        </div>
        <PlusOutlined className="plus-icon" />
        <div className="main4-card">
          <div className="main4-card-title">
            <span className="small">$0.50</span>
          </div>
          <div className="main4-card-content">
            per additional 1 million credits:
          </div>
          <div className="main4-card-list">
            <div className="main4-card-item">
              <div className="dot"></div>
              <div className="list-content">3 hours speech-to-text</div>
            </div>
            <div className="main4-card-item">
              <div className="dot"></div>
              <div className="list-content">
                or 1 million Llama-8B chat tokens
              </div>
            </div>
            <div className="main4-card-item">
              <div className="dot"></div>
              <div className="list-content">or 600k Mixtral tokens</div>
            </div>
            <div className="main4-card-item">
              <div className="dot"></div>
              <div className="list-content">or 100 images</div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="start-btn2"
        size="large"
        style={{ marginTop: "1.43em", fontWeight: 600 }}
      >
        Start Your Free Trial >
      </Button>
      <div className="btn-foot">
        Not a developer?
        <span className="link">
          Use Transcripo to convert speech to text for free
          <ExportOutlined style={{ marginLeft: "0.5em" }} />
        </span>
      </div>
    </Style>
  );
}
