import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import PageHeader from "../components/pageHeader";
import YourProfile from "../components/setting/YourProfile";
import TopUp from "../components/setting/user";
import Usage from "../components/setting/usage";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";

import {
  UserOutlined,SettingOutlined,PieChartOutlined,LoginOutlined
} from "@ant-design/icons";
import {
  Menu,
  Layout,
  Modal
} from "antd";
const { Header, Content, Sider } = Layout;

const Style = styled.div`
.header {
    height: 64px;
    display: flex;
    align-items: center;
    width: "100%";
    border-bottom: 1px solid #eee;
  }
  .icon-pointer {
    cursor: pointer;
  }
`;
export default function Setting({notify, setLoading, logined, setLogined}) {
  const [collapsed, setCollapsed] = useState(false);
  const [currMenuKey, setCurrMenuKey] = useState("1");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelContent, setModelContent] = useState({
    title: "提示",
    content: "<p>内容</p>",
    okText: "确定",
    cancelText: "取消",
    maskClosable: false,
    onOk: () => {
      console.log("ok");
    },
    onCancel: () => {
      console.log("cancel");
    },
  })

  useEffect(() => {
    document.title = "Voila API - Setting"
    let localMenuId = localStorage.getItem('currMenuKey')
    if (localMenuId) {
      setCurrMenuKey(localMenuId)
      document.getElementsByClassName('ant-menu-item')[localMenuId - 1].click()
    }
  }, [])

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: []
  ) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    getItem("API Key Management", "1", <SettingOutlined />),
    getItem("Top Up", "2", <UserOutlined />),
    getItem("Usage", "3", <PieChartOutlined />),
    getItem("Log out", "0", <LoginOutlined />),
    // getItem("User", "sub1", <UserOutlined />, [
    //   getItem("Tom", "3"),
    //   getItem("Bill", "4"),
    //   getItem("Alex", "5"),
    // ]),
    // getItem("Team", "sub2", <TeamOutlined />, [
    //   getItem("Team 1", "6"),
    //   getItem("Team 2", "8"),
    // ]),
    // getItem("Files", "9", <FileOutlined />),
  ];

  const onMenuSelect = useCallback((e) => {
    if(e.key === '0') {
      let currKey = localStorage.getItem('currMenuKey')
      setModelContent({
        title: "Warning!",
        content: "Confirm to logout?",
        okText: "Confirm",
        cancelText: "Cancel",
        maskClosable: false,
        onOk: () => {
          cookie.remove("token")
          cookie.remove("refresh_token")
          cookie.remove("expires_time")
          setLogined(false)
          setIsModalOpen(false);
          navigate("/login");
        },
        onCancel: () => {
          setTimeout(() => {
            document.getElementsByClassName('ant-menu-item')[currKey - 1].click()
          }, 100)
          setIsModalOpen(false);
        },
      })
      setIsModalOpen(true)
      
    } else {
      setCurrMenuKey(e.key)
      localStorage.setItem('currMenuKey', e.key)
    }
  }, [setLogined])

  const settingProps = {notify, setLoading, logined}

  return (
    <Style>
      <Header className="header">
        <PageHeader pathname='/setting' routename='api key管理' />
      </Header>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="light"
            mode="inline"
            items={items}
            onSelect={(e) => {
              onMenuSelect(e)
            }}
          />
        </Sider>
        <Content style={{ margin: "16px" }}>
          {currMenuKey === '1' && <YourProfile {...settingProps}  />}
          {currMenuKey === '2' && <TopUp {...settingProps}  />}
          {currMenuKey === '3' && <Usage {...settingProps}  />}
        </Content>
      </Layout>
      <Modal title={modelContent.title} open={isModalOpen} onOk={modelContent.onOk} onCancel={modelContent.onCancel} maskClosable={modelContent.maskClosable} okText={modelContent.okText} cancelText={modelContent.cancelText}>
        {modelContent.content}
      </Modal>
    </Style>
  );
}
