import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";

const Style = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }

  padding: 1.14rem;
  color: #000;
  .header-left {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;

    .logo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
  .header-link {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-breadcrumb a {
    color: #fff !important;
  }
  .ant-breadcrumb-separator {
    color: #fff !important;
  }
  .ant-breadcrumb-link {
    color: #ccc !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .ml-15 {
    margin-left: 15px;
  }
  .cursor-pointer:hover {
    opacity: 0.8;
  }
`;

export default function PageHeader({ pathname, routename }) {
  const navigate = useNavigate();

  const navigateToSetting = (id) => {
    localStorage.setItem("currMenuKey", id);
    let pathname = window.location.pathname;
    if (pathname !== "/setting") navigate("/setting");
    else {
      document.getElementsByClassName("ant-menu-item")[id - 1].click();
    }
  };
  return (
    <Style>
      <div
        className="header-left"
        onClick={() => (window.location.href = "https://ai.voilatech.co.jp/")}
      >
        <img className="logo" src="/images/logo.png" alt="logo加载失败" />
        <span style={{ fontWeight: "bold" }}>
          ai.<span style={{ color: "orange" }}>Voilatech</span>.co.jp
        </span>
      </div>
      {/* <Breadcrumb
        items={
          pathname !== "/"
            ? [
                {
                  href: "/",
                  title: (
                    <>
                      <HomeOutlined />
                      <span>首页</span>
                    </>
                  ),
                },
                {
                  // href: pathname,
                  title: routename,
                },
              ]
            : [
                {
                  href: "/",
                  title: (
                    <>
                      <HomeOutlined />
                      <span>首页</span>
                    </>
                  ),
                },
              ]
        }
      /> */}
      <div>
        <span
          className="cursor-pointer header-link ml-15"
          onClick={() => {
            // navigate("/Docs");
            window.location.href = "https://ai.voilatech.co.jp/docs/";
          }}
        >
          Docs
        </span>
        <span
          className="cursor-pointer header-link ml-15"
          onClick={() => {
            navigateToSetting("1");
          }}
        >
          Account
        </span>
        <span
          className="cursor-pointer header-link ml-15"
          onClick={() => {
            window.location.href = "https://ai.voilatech.co.jp/guides";
          }}
        >
          Guides
        </span>
        {/* <UserOutlined
          className="cursor-pointer ml-15"
          onClick={() => {
            navigateToSetting('2')
          }}
        /> */}
      </div>
    </Style>
  );
}
