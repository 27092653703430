import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import NotificationSystem from "react-notification-system";
import DT from "duration-time-conversion";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import Tool from "./components/Tool";
import Subtitles from "./components/Subtitles";
import Player from "./components/Player";
import Footer from "./components/Footer";
import ProgressBar from "./components/ProgressBar";
import { getKeyCode } from "./utils";
import Sub from "./libs/Sub";
import { operationApi, editApi } from "./api/index";
import { serveEditApi } from "./api/serve";
import UploadPage from "./pages/UploadPage";
import Controler from "./components/Controler";
import { Modal } from 'antd';
import { message } from "antd";


const Style = styled.div`
  height: 100%;
  width: 100%;
  .hideupload {
    display: none;
  }
  .showupload {
    height: 100%;
    // display: none;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .hidecontain {
    display: none;
  }
  .header {
    // height: 100px;
    overflow: hidden;
  }
  .main {
    flex: 1;
    display: flex;
    height: calc(100% - 200px);

    .main-left {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .subtitles {
      // width: 600px;
    }
  }

  .footer {
    // height: 200px;
    display: none;
  }
  .hide-footer {
  }
`;

export default function HomePage({
  defaultLang,
  logined,
  setLogined,
  notify,
  setLoading,
  info,
  updateInfo
}) {
  const subtitleHistory = useRef([]);
  const notificationSystem = useRef(null);
  const [player, setPlayer] = useState(null);

  const [processing, setProcessing] = useState(0);
  const [language, setLanguage] = useState(defaultLang);
  const [subtitle, setSubtitleOriginal] = useState([]);
  const [waveform, setWaveform] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [videoId, setVideoId] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  const newSub = useCallback((item) => new Sub(item), []);
  const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);
  // 对话框
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelContent, setModelContent] = useState({
    title: "提示",
    content: "<p>内容</p>",
    okText: "确定",
    cancelText: "取消",
    maskClosable: false,
    onOk: () => {
      console.log("ok");
    },
    onCancel: () => {
      console.log("cancel");
    },
  })

  const formatSub = useCallback(
    (sub) => {
      if (Array.isArray(sub)) {
        return sub.map((item) => newSub(item));
      }
      return newSub(sub);
    },
    [newSub]
  );

  const copySubs = useCallback(() => formatSub(subtitle), [
    subtitle,
    formatSub,
  ]);

  const setSubtitle = useCallback(
    (newSubtitle, saveToHistory = true) => {
      if (!isEqual(newSubtitle, subtitle)) {
        if (saveToHistory) {
          if (subtitleHistory.current.length >= 1000) {
            subtitleHistory.current.shift();
          }
          subtitleHistory.current.push(formatSub(subtitle));
        }
        window.localStorage.setItem("subtitle", JSON.stringify(newSubtitle));
        setSubtitleOriginal(newSubtitle);
      }
    },
    [subtitle, setSubtitleOriginal, formatSub]
  );

  const undoSubs = useCallback(() => {
    const subs = subtitleHistory.current.pop();
    if (subs) {
      setSubtitle(subs, false);
    }
  }, [setSubtitle, subtitleHistory]);

  const clearSubs = useCallback(() => {
    setSubtitle([]);
    subtitleHistory.current.length = 0;
  }, [setSubtitle, subtitleHistory]);

  const checkSub = useCallback(
    (sub) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const previous = subtitle[index - 1];
      return (
        (previous && sub.startTime < previous.endTime) ||
        !sub.check ||
        sub.duration < 0.2
      );
    },
    [subtitle, hasSub]
  );

  const removeSub = useCallback(
    (sub) => {
      console.log("removeSub", sub);
      const index = hasSub(sub);
      console.log("index", index);
      if (index < 0) return;
      const subs = copySubs();
      subs.splice(index, 1);
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle]
  );

  const addSub = useCallback(
    (index, sub) => {
      const subs = copySubs();
      subs.splice(index, 0, formatSub(sub));
      setSubtitle(subs);
    },
    [copySubs, setSubtitle, formatSub]
  );

  const updateSub = useCallback(
    (sub, obj) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const subs = copySubs();
      const subClone = formatSub(sub);
      Object.assign(subClone, obj);
      if (subClone.check) {
        subs[index] = subClone;
        setSubtitle(subs);
      }
    },
    [hasSub, copySubs, setSubtitle, formatSub]
  );

  const mergeSub = useCallback(
    (sub) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const subs = copySubs();
      const next = subs[index + 1];
      if (!next) return;
      const merge = newSub({
        start: sub.start,
        end: next.end,
        text: sub.text.trim() + "\n" + next.text.trim(),
      });
      subs[index] = merge;
      subs.splice(index + 1, 1);
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle, newSub]
  );

  const splitSub = useCallback(
    (sub, start) => {
      const index = hasSub(sub);
      if (index < 0 || !sub.text || !start) return;
      const subs = copySubs();
      const text1 = sub.text.slice(0, start).trim();
      const text2 = sub.text.slice(start).trim();
      if (!text1 || !text2) return;
      const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(
        3
      );
      if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
      subs.splice(index, 1);
      const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
      subs.splice(
        index,
        0,
        newSub({
          start: sub.start,
          end: middleTime,
          text: text1,
        })
      );
      subs.splice(
        index + 1,
        0,
        newSub({
          start: middleTime,
          end: sub.end,
          text: text2,
        })
      );
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle, newSub]
  );

  const onKeyDown = useCallback(
    (event) => {
      const keyCode = getKeyCode(event);
      switch (keyCode) {
        case 32:
          event.preventDefault();
          if (player) {
            if (playing) {
              player.pause();
            } else {
              player.play();
            }
          }
          break;
        case 90:
          event.preventDefault();
          if (event.metaKey) {
            undoSubs();
          }
          break;
        default:
          break;
      }
    },
    [player, playing, undoSubs]
  );

  const subChange = (idx, newText) => {
    const startTime = Date.now();
    let uid = localStorage.getItem("uid");
    if(!uid || uid === '') {
      notify({
        message: "字幕编辑失败，请重新上传视频",
        level: "error",
      })
      return
    }
    let editQuery = {
      index: idx+ '',
      text: newText,
      uid: uid,
      version: '0'
    }
    console.log('editQuery:', editQuery)
    editApi(editQuery).then((res) => {
      const endTime = Date.now();
      const duration = endTime - startTime;
      console.log("edit res:");
      console.log(res);
      // let { srt } = res;
      const videoId = localStorage.getItem("video_id");
      const uid = localStorage.getItem("uid");
      let logData = {
        whisperStr: "",
        uid: uid,
        subtitlesStr: localStorage.getItem("subtitle"),
      };
      onOperation({
        video_id: videoId,
        operation: 3, // 编辑
        data: logData,
        time_spent: duration,
        status: 0, // 0：成功，1：失败
      });
    }).catch(err => {
      let logData = {
        whisperStr: "",
        uid: localStorage.getItem("uid"),
        subtitlesStr: localStorage.getItem("subtitle"),
      };
      const endTime = Date.now();
      const duration = endTime - startTime;
      onOperation({
        video_id: localStorage.getItem("video_id"),
        operation: 3, // 编辑
        data: logData,
        time_spent: duration,
        status: 1, // 0：成功，1：失败
      });
    });
  };

  const onOperation = (data) => {
    operationApi(data).then((res) => {
      console.log("operationApi res:");
      console.log(res);
      if (res.code === 0) {
        let temp = Object.assign({}, info);
        temp.balance = res.data.balance;
        updateInfo(temp)
      } else {
        notify({
          message: "操作记录失败",
          level: "error",
        });
      }
    });
  };

  useEffect(() => {
    // console.log('login info')
    // console.log(logined)
    document.title = "Voila API - Home"
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown, logined]);

  useMemo(() => {
    const currentIndex = subtitle.findIndex(
      (item) => item.startTime <= currentTime && item.endTime > currentTime
    );
    setCurrentIndex(currentIndex);
  }, [currentTime, subtitle]);

  useEffect(() => {
    // const localSubtitleString = window.localStorage.getItem("subtitle");
    // const fetchSubtitle = () =>
    //   fetch("/sample.json")
    //     .then((res) => res.json())
    //     .then((res) => {
    //       setSubtitleOriginal(res.map((item) => new Sub(item)));
    //     });

    // if (localSubtitleString) {
    //   try {
    //     const localSubtitle = JSON.parse(localSubtitleString);
    //     if (localSubtitle.length) {
    //       setSubtitleOriginal(localSubtitle.map((item) => new Sub(item)));
    //     } else {
    //       fetchSubtitle();
    //     }
    //   } catch (error) {
    //     fetchSubtitle();
    //   }
    // } else {
    //   fetchSubtitle();
    // }
  }, [setSubtitleOriginal]);

  const props = {
    player,
    setPlayer,
    subtitle,
    setSubtitle,
    waveform,
    setWaveform,
    currentTime,
    setCurrentTime,
    currentIndex,
    setCurrentIndex,
    playing,
    setPlaying,
    language,
    setLanguage,
    fullScreen,
    setFullScreen,

    setProcessing,
    subtitleHistory,
    videoId,
    setVideoId,
    videoFile,
    setVideoFile,
    logined,
    setLogined,

    notify,
    setLoading,
    newSub,
    hasSub,
    checkSub,
    removeSub,
    addSub,
    undoSubs,
    clearSubs,
    updateSub,
    formatSub,
    mergeSub,
    splitSub,
    subChange,
    onOperation,
    info,
    updateInfo,
    setIsModalOpen,
    setModelContent
  };


  return (
    <Style>
      <div className={videoFile ? "hideupload" : "showupload"}>
        <UploadPage {...props} />
      </div>
      <div
        className={
          videoFile ? "container showcontain" : "container hidecontain"
        }
      >
        <div className="header">
          <Tool {...props} />
        </div>
        <div className="main">
          <div className="main-left">
            <Player {...props} />
            {/* <Controler {...props} /> */}
          </div>
          <Subtitles {...props} />
        </div>
        <Footer {...props} className="hide-footer" />
      </div>
      {processing > 0 && processing < 100 ? (
        <ProgressBar processing={processing} />
      ) : null}
      <Modal title={modelContent.title} open={isModalOpen} onOk={modelContent.onOk} onCancel={modelContent.onCancel} maskClosable={modelContent.maskClosable} okText={modelContent.okText} cancelText={modelContent.cancelText}>
        {modelContent.content}
      </Modal>
    </Style>
  );
}
