import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Layout,
  Input,
  InputNumber,
  Radio,
  Space,
  Descriptions,
} from "antd";
import { orderCreateApi, orderCaptureApi, userInfoApi } from "../api";
import { useLocation } from "react-router-dom";
import PageHeader from "../components/pageHeader";

const { Header, Content, Sider } = Layout;

const Style = styled.div`
  
  .header {
    height: 64px;
    display: flex;
    align-items: center;
    width: "100%";
  }
  .main-content {
    width: 100%;
    height: calc(100vh - 64px);
    padding: 20px;
  }
  .paypal-btn {
    width: 200px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    background: #ffc439;
    .paypal-img {
      width: 70px;
      height: auto;
    }
  }
  .paypal-btn:hover {
    background: #e6b033;
  }
`;

export default function UserPage({ notify, setLoading }) {
  const [value, setValue] = useState(10);
  const [cusVal, setCusVal] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const location = useLocation();

  useEffect(() => {
    async function effectFun() {
      const query = new URLSearchParams(location.search);
      let action = query.get("action");
      if (!action) {
        getUserInfo();
        return;
      } else {
        setLoading("获取支付结果中");
        if (action === "success") {
          console.log("success");
          let token = query.get("token");
          await checkOrder(token);
        } else if (action === "cancel") {
          setLoading("");
          notify({
            message: "支付失败",
            level: "error",
          });
        }
      }
    }
    effectFun();
  }, [setLoading]);

  const checkOrder = useCallback(
    async (token) => {
      console.log("checkOrder", token);
      orderCaptureApi({
        action: "capture",
        id: token,
      }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          notify({
            message: "支付成功",
            level: "success",
          });
          getUserInfo();
        } else {
          setLoading("");
          notify({
            message: "支付失败",
            level: "error",
          });
        }
      });
    },
    [setLoading, notify]
  );

  const getUserInfo = useCallback(() => {
    userInfoApi().then((res) => {
      if (res.code === 0) {
        setUserInfo(res.data);
        setLoading("");
      }
    });
  }, [setLoading]);

  /* 
  {
    "account": "18675311283@163.com",
    "avatar": 1,
    "balance": "10.000000",
    "create_time": 1719293424,
    "id": 4,
    "last_login_ip": "127.0.0.1",
    "last_login_time": 1719888895,
    "mobile": "",
    "nickname": "18675311283@163.com",
    "sex": 0,
    "update_time": 0
}
  */

  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      console.log("radio checked", e.target.value);
      setValue(e.target.value);
    },
    [setValue]
  );
  const customChange = useCallback(
    (e) => {
      setCusVal(e.target.value);
    },
    [setCusVal]
  );

  const onConfirm = useCallback(() => {
    setLoading("即将跳转paypal支付");
    let amount = value === 4 ? cusVal : value;
    if (typeof amount !== "number") {
      setLoading("");
      notify({
        message: "请输入充值金额",
        level: "error",
      });
      return;
    }
    if (!amount || amount <= 0) {
      setLoading("");
      notify({
        message: "请输入充值金额",
        level: "error",
      });
      return;
    }
    orderCreateApi({ amount })
      .then((res) => {
        console.log(res);
        if (res.code === 0) {
          let redirectUrl = res.data.links.find(
            (item) => item.rel === "payer-action"
          ).href;
          window.location.href = redirectUrl;
        }
      })
      .catch((err) => {
        setLoading("");
        notify({
          message: "跳转支付失败",
          level: "error",
        });
      });
  }, [setLoading, value, cusVal]);

  /* 
  {
    "id": "0MC29602NW4806718",
    "links": [
        {
            "href": "https://api.sandbox.paypal.com/v2/checkout/orders/0MC29602NW4806718",
            "method": "GET",
            "rel": "self"
        },
        {
            "href": "https://www.sandbox.paypal.com/checkoutnow?token=0MC29602NW4806718",
            "method": "GET",
            "rel": "payer-action"
        }
    ],
    "payment_source": {
        "paypal": {}
    },
    "status": "PAYER_ACTION_REQUIRED"
}
  */
  const sexMap = {
    1: "男",
    2: "女",
    0: "未知",
  };

  return (
    <Style>
      {/* <Header className="header">
        <PageHeader pathname="/user" routename="用户中心" />
      </Header> */}
      <div className="main-content">
        <Descriptions
          title="个人信息"
          bordered
          style={{ background: "#fff", borderRadius: "4px", padding: "16px" }}
        >
          <Descriptions.Item label="账号">{userInfo.account}</Descriptions.Item>
          <Descriptions.Item label="性别">
            {sexMap[userInfo.sex]}
          </Descriptions.Item>
          <Descriptions.Item label="余额">
            $<span style={{ color: "red", marginLeft: 5 }}>{userInfo.balance}</span>
          </Descriptions.Item>
        </Descriptions>
        <Card
          title="充值"
          bordered={false}
          style={{ width: 300, marginTop: "20px" }}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={10}>10$</Radio>
              <Radio value={20}>20$</Radio>
              <Radio value={50}>50$</Radio>
              <Radio value={4}>
                自定义
                {value === 4 ? (
                  <Input
                    onChange={customChange}
                    style={{ width: 100, marginLeft: 10 }}
                  />
                ) : null}
              </Radio>
            </Space>
          </Radio.Group>
          <div className="paypal-btn" onClick={onConfirm}>
            <img className="paypal-img" src="/images/paypal.svg" alt="paypal" />
          </div>
        </Card>
      </div>
    </Style>
  );
}
