import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import { Table } from "react-virtualized";
import unescape from "lodash/unescape";
import debounce from "lodash/debounce";
import { Tag, Input } from "antd";
import { ClockCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import DT from "duration-time-conversion";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration);

const Style = styled.div`
  position: relative;
  box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
  background-color: rgb(0 0 0 / 100%);

  .ReactVirtualized__Table {
    .ReactVirtualized__Table__Grid {
      outline: none;
    }

    .ReactVirtualized__Table__row {
      .item {
        height: 100%;
        padding: 5px;
        display: flex;
        .subs {
          flex: 1;
        }

        .textarea {
          border: none;
          width: 100%;
          height: 100px;
          color: #fff;
          font-size: 12px;
          padding: 10px;
          text-align: center;
          background-color: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          transition: all 0.2s ease;
          resize: none;
          outline: none;

          &.hidetext2 {
            display: none;
          }

          &.highlight {
            background-color: rgb(0 87 158);
            border: 1px solid rgba(255, 255, 255, 0.3);
          }

          &.illegal {
            background-color: rgb(123 29 0);
            border: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
        .item-end {
          width: 160px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .delete-icon {
          cursor: pointer;
          align-self: center;
          color: red;
          font-size: 25px;
          border-radius: 50%;
          border: 2px solid red;
          width: 30px;
          height: 30px;
          padding: 5px;
        }
      }
    }
  }
`;

export default function Subtitles({
  currentIndex,
  subtitle,
  checkSub,
  player,
  updateSub,
  subChange,
  removeSub,
  notify,
}) {
  const [height, setHeight] = useState(150);
  const [timeEditIdx, setTimeEditIdx] = useState(-1);
  const [timeEdit, setTimeEdit] = useState("");
  const [timeEditType, setTimeEditType] = useState("");

  const resize = useCallback(() => {
    setHeight(document.body.clientHeight - 20);
  }, [setHeight]);

  useEffect(() => {
    resize();
    if (!resize.init) {
      resize.init = true;
      const debounceResize = debounce(resize, 500);
      window.addEventListener("resize", debounceResize);
    }
  }, [resize]);

  const clickRemove = useCallback(
    (event, sub) => {
      event.stopPropagation();
      removeSub(sub);
    },
    [removeSub]
  );

  const clickSubEnd = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const getDuration = useCallback((time) => {
    // time为秒数
    time = time === Infinity ? 0 : time;
    return dayjs.duration(time, 'seconds').format('mm:ss:SSS');
    // return DT.d2t(time).split(".")[0];
    // return DT.d2t(time) // "00:00:00.000"
  }, []);

  const validateTime = (timeStr) => {
    // mm:ss:SSS
    return dayjs(timeStr, 'mm:ss:SSS').isValid()
    // const regex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;
    // return regex.test(timeStr);
  };
  const timeStringToSeconds = (timeString) => {
    // 时分秒转换为秒数
    // const [hours, minutes, seconds] = timeString.split(":").map(Number);
    // return hours * 3600 + minutes * 60 + seconds;
    // 分秒毫秒转换为秒数
    const [minutes, seconds, millSeconds] = timeString.split(":").map(Number);
    return millSeconds / 1000 + seconds + minutes * 60;
  };

  const startTimeBlur = useCallback(
    (event, rowData, rowIdx) => {
      let blurValue = event.target.value;
      if (!validateTime(blurValue)) {
        notify({
          message: "请输入正常格式的时间",
          level: "error",
        });
        return;
      }
      let seconds = timeStringToSeconds(blurValue);
      console.log(seconds);
      let minTime = rowIdx > 0 ? subtitle[rowIdx - 1].endTime : 0,
        maxTime = rowData.endTime;
      console.log(minTime, maxTime);
      if (seconds < minTime || seconds > maxTime) {
        notify({
          message: "字幕时间不能重叠",
          level: "error",
        });
        updateSub(rowData, {
          startTime: subtitle[rowIdx].startTime,
        });
        setTimeEditIdx(-1);
      } else {
        updateSub(rowData, {
          startTime: seconds,
        });
        setTimeEditIdx(-1);
      }
      console.log(minTime, maxTime);
    },
    [subtitle, getDuration, updateSub, setTimeEditIdx]
  );

  const endTimeBlur = useCallback(
    (event, rowData, rowIdx) => {
      let blurValue = event.target.value;
      if (!validateTime(blurValue)) {
        notify({
          message: "请输入正常格式的时间",
          level: "error",
        });
        return;
      }
      let seconds = timeStringToSeconds(blurValue);
      let minTime = rowData.startTime,
        maxTime =
          rowIdx < subtitle.length - 1
            ? subtitle[rowIdx + 1].startTime
            : player.duration;
      if (seconds < minTime || seconds > maxTime) {
        notify({
          message: "字幕时间不能重叠",
          level: "error",
        });
        updateSub(rowData, {
          endTime: subtitle[rowIdx].endTime,
        });
        setTimeEditIdx(-1);
      } else {
        updateSub(rowData, {
          endTime: seconds,
        });
        setTimeEditIdx(-1);
      }
    },
    [subtitle, getDuration, updateSub, setTimeEditIdx, player]
  );

  return (
    <Style className="subtitles">
      <Table
        headerHeight={40}
        width={800}
        height={height}
        rowHeight={subtitle.length && subtitle[0].text2 ? 200 : 100}
        scrollToIndex={currentIndex}
        rowCount={subtitle.length}
        rowGetter={({ index }) => subtitle[index]}
        headerRowRenderer={() => null}
        rowRenderer={(props) => {
          return (
            <div
              key={props.key}
              className={props.className}
              style={props.style}
              onClick={() => {
                if (player) {
                  player.pause();
                  if (player.duration >= props.rowData.startTime) {
                    player.currentTime = props.rowData.startTime + 0.001;
                  }
                }
              }}
            >
              <div className="item">
                <div className="subs">
                  <textarea
                    spellCheck={false}
                    className={[
                      "textarea",
                      currentIndex === props.index ? "highlight" : "",
                      checkSub(props.rowData) ? "illegal" : "",
                    ]
                      .join(" ")
                      .trim()}
                    value={unescape(props.rowData.text)}
                    onChange={(event) => {
                      updateSub(props.rowData, {
                        text: event.target.value,
                      });
                    }}
                    onBlur={(event) => {
                      subChange(props.index, event.target.value);
                    }}
                  />
                  {props.rowData.text2 && (
                    <textarea
                      className={[
                        "textarea",
                        props.rowData.text2 ? "" : "hidetext2",
                        currentIndex === props.index ? "highlight" : "",
                        checkSub(props.rowData) ? "illegal" : "",
                      ]
                        .join(" ")
                        .trim()}
                      value={unescape(props.rowData.text2)}
                      onChange={(event) => {
                        updateSub(props.rowData, {
                          text2: event.target.value,
                        });
                      }}
                    />
                  )}
                </div>
                <div className="item-end" onClick={clickSubEnd}>
                  <div style={{ marginBottom: "10px" }}>
                    <ClockCircleOutlined style={{ marginRight: "10px" }} />
                    进：
                    {timeEditIdx === props.index && timeEditType === "start" ? (
                      <Input
                        className="starttime-input"
                        defaultValue={getDuration(props.rowData.startTime)}
                        onChange={(event) => {
                          setTimeEdit(event.target.value);
                        }}
                        size="small"
                        style={{ width: "80px" }}
                        onBlur={(event) => {
                          startTimeBlur(event, props.rowData, props.index);
                        }}
                      />
                    ) : (
                      <Tag
                        style={{ width: "80px", cursor: "pointer" }}
                        onClick={(event) => {
                          setTimeEditIdx(props.index);
                          setTimeEditType("start");
                          setTimeout(() => {
                            document
                              .getElementsByClassName("starttime-input")[0]
                              .focus();
                          }, 50);
                        }}
                      >
                        {getDuration(props.rowData.startTime)}
                      </Tag>
                    )}
                  </div>
                  <div>
                    <ClockCircleOutlined style={{ marginRight: "10px" }} />
                    出：
                    {timeEditIdx === props.index && timeEditType === "end" ? (
                      <Input
                        className="endtime-input"
                        defaultValue={getDuration(props.rowData.endTime)}
                        onChange={(event) => {
                          setTimeEdit(event.target.value);
                        }}
                        size="small"
                        style={{ width: "80px" }}
                        onBlur={(event) => {
                          endTimeBlur(event, props.rowData, props.index);
                        }}
                      />
                    ) : (
                      <Tag
                        style={{ width: "80px", cursor: "pointer" }}
                        onClick={(event) => {
                          setTimeEditIdx(props.index);
                          setTimeEditType("end");
                          setTimeout(() => {
                            document
                              .getElementsByClassName("endtime-input")[0]
                              .focus();
                          }, 50);
                        }}
                      >
                        {getDuration(props.rowData.endTime)}
                      </Tag>
                    )}
                  </div>
                </div>
                <DeleteOutlined
                  className="delete-icon"
                  onClick={(e) => {
                    clickRemove(e, props.rowData);
                  }}
                />
              </div>
            </div>
          );
        }}
      ></Table>
    </Style>
  );
}
