import { useState, useCallback, useEffect } from "react";
import { Button, Form, Input, Space, notification } from "antd";
import axios from "axios";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { transform } from "lodash";
import { sendCodeApi, loginApi } from "../api/index";
import PageHeader from "../components/pageHeader";
import {
  Layout,
} from "antd";
const { Header, Content, Sider } = Layout;

const Style = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  .login-form {
    margin: 20% auto;
    width: 500px;
    .ant-form-item-required {
      color: #333 !important;
    }
    /* @media (max-width: 1000px) {
      width: 500px;
    } */
    @media (max-width: 576px) {
      width: 90%;
    }
    .submit-btn {
      .ant-form-item-control {
        @media (max-width: 576px) {
          margin-left: 0 !important;
        }
      }
    }
  }
`;

const getCurrentTime = () => {
  return parseInt(Date.now() / 1000);
};

// eslint-disable-next-line react/prop-types
function LoginPage({ setLogined }) {
  const [reciver, setReciver] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    document.title = "Voila API - Login"
    // setLogined(false)
  }, [setLogined])

  const reciverChange = useCallback(
    (e) => {
      setReciver(e.target.value);
    },
    [setReciver]
  );

  const sendCode = useCallback(() => {
    if (
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(reciver) === false
    ) {
      notification.error({ message: "号码格式错误" });
      return;
    }

    sendCodeApi({ reciver: reciver, type: 'login_' }).then(res => {
      console.log(res);
      let button = document.getElementById("get_code_login");
        button.disabled = true;
        button.style.background = "#fff";
      if (res.code === 0) {
          let count = 60;
          const countdown = setInterval(function () {
            button.innerHTML = count;
            count--;
            if (count <= 0) {
              clearInterval(countdown);
              button.disabled = false;
              button.innerHTML = "获取验证码";
              count = 60; // 重置倒计时
            }
          }, 1000);
      } else {
        notification.error({ message: res.msg });
      }
    })

  }, [reciver]);

  const onFinish = useCallback((values) => {
    console.log(values);
    loginApi(values).then(res => {
      if (res.code === 0) {
        let { token, refresh_token, expires_time } = res.data
        // sessionStorage.setItem("token", token);
            console.log(res.data);
            cookie.save("token", token);
            cookie.save("refresh_token", refresh_token);
            cookie.save("expires_time", expires_time);
            // cookie.save("zuplo_key", zuplo_key);
            setLogined(true)
            notification.success({
              message: "Login Success!",
              duration: 1,
              onClose: () => {
                if(window.location.pathname === '/login') {
                  localStorage.setItem("currMenuKey", 2)
                  window.location.href = '/setting'
                }
              },
            });
      } else {
        notification.error({ message: res.msg });
      }
    })
  }, [setLogined]);

  const onFinishFailed = (errorInfo) => {
    notification.error({ message: "Failed!" });
  };

  const validateEmail = (rule, value) => {
    const emailRegex = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
    if (!value) {
      return Promise.resolve();
    }
    if (!emailRegex.test(value)) {
      return Promise.reject("Format error");
    }
    return Promise.resolve();
  };

  return (
    <Style>
      <Header className="header">
      <PageHeader pathname='/login' routename='登录' />
      </Header>
      <Form
        className="login-form"
        name="login"
        size="large"
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelCol={{
          span: 5
        }}
      >
        <Form.Item
          label="Email"
          name="reciver"
          rules={[
            {
              required: true,
              message: "Enter Email!",
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input type="email" value={reciver} onChange={reciverChange} />
        </Form.Item>
        <Form.Item
          label="Verify Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Enter Verify Code!",
            },
          ]}
        >
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Form.Item name="code" noStyle>
              <Input value={code} onChange={(e) => setCode(e.target.value)} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                id="get_code_login"
                style={{ width: "50%" }}
                onClick={sendCode}
              >
                Send Code
              </Button>
            </Form.Item>
          </Space.Compact>
        </Form.Item>

        <Form.Item
          className="submit-btn"
          wrapperCol={{
            offset: 5,
          }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Style>
  );
}

export default LoginPage;
