// App.js
import React, { useRef, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/loginPage"; // 引入登录页面组件
import HomePage from "./homePage";
import UserPage from "./pages/user";
import SettingPage from "./pages/setting";
import ScalarPage from "./pages/scalar";
import styled from "styled-components";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import { userInfoApi } from "./api";
import { isLogined } from "./utils";
import NotificationSystem from "react-notification-system";
import Loading from "./components/Loading";
import StaticHome from "./pages/staticHome";
import Mobile from './Mobile';
import { isMobile } from './utils';

const Style = styled.div`
  height: 100%;
  width: 100%;
  
  // @font-face {
  //   font-family: KaTeX_Main;
  //   font-style: normal;
  //   font-weight: 400;
  //   src: url(/font/KaTeX_Main-Regular.woff2) format("woff2"),
  //     url(/font/KaTeX_Main-Regular.woff) format("woff"),
  //     url(/font/KaTeX_Main-Regular.ttf) format("truetype");
  // }
  .ant-layout-header {
    // font-family: KaTeX_Main;
    // font-style: normal;
    font-family:"Helvetica Neue", Helvetica, "Segoe UI", Arial, freesans, sans-serif;
    background: #fff;
  }
`;
function App() {
  const [logined, setLogined] = useState(false);
  const notificationSystem = useRef(null);
  const [loading, setLoading] = useState("");
  const info = useRef(null);

  const updateInfo = (obj) => {
    info.current = obj;
    console.log("updateInfo", obj);
  };

  const notify = useCallback(
    (obj) => {
      // https://github.com/igorprado/react-notification-system
      const notification = notificationSystem.current;
      notification.clearNotifications();
      notification.addNotification({
        position: "tc",
        dismissible: "none",
        autoDismiss: 2,
        message: obj.message,
        level: obj.level,
      });
    },
    [notificationSystem]
  );

  useEffect(() => {
    
    const effectFun = async () => {
      const token = cookie.load("token");
      const expires_time = cookie.load("expires_time");
      let expired = expires_time * 1000 <= Date.now();
      if (expired || !token || token === "undefined") {
        // 未登录
        notify({
          message: "请先登录",
          level: "error",
        });
      } else {
        setLogined(true);
        await userInfoApi().then((res) => {
          if (res.code === 0) {
            info.current = res.data;
          }
        });
      }
    };


    /* const setTitleByPath = () => {
      switch (location.pathname) {
        case '/':
          setTitle('Voila API - Home');
          break;
        case '/setting':
          setTitle('Voila API - Setting');
          break;
        case '/login':
          setTitle('Voila API - Login');
          break;
        default:
          setTitle('Voila API');
      }
    };
    setTitleByPath(); */
    effectFun();
  }, []);


  const appProps = {
    logined,
    setLogined,
    notify,
    loading,
    setLoading,
    info,
    updateInfo,
  };
  const navigateToLogin = () => {
    console.log("navigateToLogin");
    window.location.replace("/login");
    // 使用 Navigate 组件进行客户端路由重定向
    // return <Navigate to="/login" replace />;
  };

  return (
    <Style>
      <Router>
        {/* 路由定义 */}
        <Routes>
          <Route path="/" title="Home" element={isMobile ? <Mobile /> : <HomePage {...appProps} />} />
          <Route path="/login" exact element={<LoginPage {...appProps} />} />
          <Route
            path="/setting"
            exact
            element={
              logined ? (
                <SettingPage {...appProps} />
              ) : (
                <LoginPage {...appProps} />
              )
            }
          />
          {/* <Route path="/user" exact element={<SettingPage {...appProps} />} /> */}
          {/* 如果没有匹配到任何路由，重定向到登录页 */}
          <Route path="/Docs" exact element={<ScalarPage />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
      <NotificationSystem ref={notificationSystem} allowHTML={true} />
      {loading ? <Loading loading={loading} /> : null}
    </Style>
  );
}

export default App;
