import request from "../utils/request";

// export const getCategory = (query) => {
//   return request({
//     url: "/category",
//     method: "get",
//     params: query,
//   });
// };
// 发送验证码
export const sendCodeApi = (data) => {
  return request({
    url: "/api/web/send_code",
    method: "post",
    data,
  });
};
// 登录
export const loginApi = (data) => {
  return request({
    url: "/api/web/login",
    method: "post",
    data,
  });
};
// 登录
export const refreshTokenApi = (refresh_token) => {
  return request({
    url: "/api/admin/refresh_token",
    method: "post",
    headers: {
      Authorization: `Bearer ${refresh_token}`
    },
  });
};
// 上传文件
export const fileUploadApi = (data) => {
  return request({
    url: "/api/file/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data,
  });
};
// 添加新视频
export const videoNewApi = (data) => {
  return request({
    url: "/api/web/video/new",
    method: "post",
    data,
  });
};
// 视频操作记录
export const operationApi = (data) => {
  return request({
    url: "/api/web/video/operation",
    method: "post",
    data,
  });
};
// 创建订单
export const orderCreateApi = (data) => {
  return request({
    url: "/api/web/order/create",
    method: "post",
    data,
  });
};
// 检查订单
export const orderCaptureApi = (data) => {
  return request({
    url: "/api/web/order/capture",
    method: "post",
    data,
  });
};
// 用户信息
export const userInfoApi = () => {
  return request({
    url: "/api/web/user/info",
    method: "post",
  });
};
// youtube download接口
// https://ytdlp-voilatech-apim.azure-api.net/ytdlp-voilatech2/download_youtube
export const ytdlApi = (url) => {
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/ytdlp-voilatech2/download_youtube?url=${url}`,
    method: "post",
  });
};
// youtube download 下载查询接口
export const ytdlProgressApi = (id) => {
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/ytdlp-voilatech2/runtime/webhooks/durabletask/instances/${id}`,
    method: "get",
  });
};

// 封装后听写接口，传音频文件,返回 id 和 code
/* //回轮询地址statusQueryGetUri，轮询检查 runtimeStatus == "Completed",
// let { uid, srt } = res.output */
export const transcribeApi = (data) => {
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/bideogo/api/transcribe`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
// 封装后的翻译接口, 返回 id 和 code
/* // 传 uid，tgt_lang = zh-cn，version = '0'
// 回轮询地址statusQueryGetUri，轮询检查 runtimeStatus == "Completed",
// let { srt } = res.output */
export const getTrApi = (data) => {
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/bideogo/api/get_tr`,
    method: "post",
    params: data,
  });
};
// 听写和翻译的轮询接口,把之前接口返回的id拼接到url上，code作为传参
// 轮询检查 runtimeStatus == "Completed", let { srt } = res.output
export const queryApi = (data) => {
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/bideogo/runtime/webhooks/durabletask/instances/${data.id}`,
    method: "get",
    params: {code: data.code}
  });
};
// 封装后的编辑接口,传 uid,index,text,version= '0'
// https://ytdlp-voilatech-apim.azure-api.net/bideogo/api/edit
export const editApi = (data) => {
  // let {index, text, ...payload} = data
  return request({
    baseURL: 'https://ytdlp-voilatech-apim.azure-api.net',
    url: `/bideogo/api/edit`,
    method: "post",
    params: data
    // data: payload
  });
};

// 添加API key（name）
export const keyAddApi = (data) => {
  return request({
    url: "/api/web/key/add",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
// API key列表
export const keyListApi = (data) => {
  return request({
    url: "/api/web/key/list",
    method: "post",
    data,
  });
};
// API key删除（key）
export const keyDeleteApi = (data) => {
  return request({
    url: "/api/web/key/delete",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
// 发送错误信息
export const errInfoApi = (api,query,res) => {
  return request({
    url: "/api/web/error/info",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {api,query,res},
  });
};
// 接口使用情况
export const usageApi = () => {
  return request({
    url: "/api/web/api/usage",
    method: "post",
  });
};
// 余额变动记录接口
export const balanceListApi = (data) => {
  return request({
    url: "/api/web/balance/list",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};