import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import PageHeader from "../components/pageHeader";
import {
  Layout,
} from "antd";
const { Header, Content, Sider } = Layout;
const Style = styled.div`
  background-color: #fff;
  .header {
    height: 64px;
    display: flex;
    align-items: center;
    width: "100%";
  }
  .iframe-scalar{
    width: 100%;
    height: calc(100vh - 64px);
  }
  .icon-pointer {
    cursor: pointer;
  }
`;
export default function ScalarPage() {
  useEffect(() => {
    document.title = "Voila API - Docs"
  }, []);
  return (
    <Style>
      <Header className="header">
        <PageHeader pathname='/Docs' routename='Docs' />
      </Header>
      <iframe
        className="iframe-scalar"
        title="scalar"
        
        src={process.env.REACT_APP_HOST+'/stablets_scalar.html'}
        frameBorder="0"
        allowFullScreen
      />
    </Style>
  );
}